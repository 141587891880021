import css from './SingleTrayManagementPage.module.css';
import Header from "components/Headers/Header";
import { formatLongDateAndTime } from 'utils/utils';
import { strings } from 'localization';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import WKTVisualizer from 'components/WKTVisualizer/WKTVisualizer';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ConfigContext } from 'context';
import { AVAILABLE_CONFIGS } from 'hooks/useConfig';
import { getImage } from 'services/api';
import { handleAxiosError } from 'utils/utils';
import { getSingleDetectionToManage } from 'services/api';
import { LinkWithQuery } from 'components/LinkWithQuery/LinkWithQuery';
import { DISH_ID } from 'utils/constants';
import ReassignRecipeModal from 'components/ReassignRecipeModal/ReassignRecipeModal';
import produce from 'immer';
import SaveModal from 'components/SaveModal/SaveModal';
import { postDetectionEdits } from 'services/api';
import { toastSuccess, toastError } from 'utils/utils';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';


function filterDetections(detections, editDict, removeDishes) {

    const editedDetections = produce(detections, draft => {
        for (const [id, recipe] of Object.entries(editDict)) {
            const idx = draft.findIndex((d) => d.id === parseInt(id));
            draft[idx].recipe_id = recipe.value;
            draft[idx].recipe_name = recipe.label;
            draft[idx].style = {};
            draft[idx].style['stroke'] = '#00FF00';
        }
    });

    if (!removeDishes) return editedDetections;
    return editedDetections.filter((d) => d['recipe_id'] !== DISH_ID)
}


export default function SingleTrayManagementPage(props) {

    const config = useContext(ConfigContext);

    const requestConfigUpdate = config.requestConfigUpdate;
    useEffect(() => {
        requestConfigUpdate([AVAILABLE_CONFIGS.FEATURES]);
    }, [requestConfigUpdate]);

    const [trayInfo, setTrayInfo] = useState({
        site_id: '',
        timestamp: -1,
        image_url: '',
        old_receipt: {},
        receipt: {},
        detections: []
    });

    const [blobUrl, setBlobUrl] = useState('');
    const [showDishes, setShowDishes] = useState(true);
    const [selectedDetection, setSelectedDetection] = useState(null);
    const [editDict, setEditDict] = useState({});
    const [saveModalOpen, setSaveModalOpen] = useState(false);
    
    const loadImageBlob = useCallback(async (url) => {
        const response = await getImage(url);
        return URL.createObjectURL(response.data);
    }, []);

    const loadDetails = useCallback(async (id) => {
        try {
            const newTrayInfo = (await getSingleDetectionToManage(id)).data;
            const newBlobUrl = await loadImageBlob(newTrayInfo.image_url);

            setTrayInfo(newTrayInfo);
            setBlobUrl(newBlobUrl);
        } catch (error) {
            handleAxiosError(error, {}, strings.singleTrayManagementPage__toastError__genericApiError);
        }
    }, [loadImageBlob]);

    useEffect(() => {
        loadDetails(props.match.params.id);
    }, [loadDetails, props.match.params.id]);

    const dateTime = formatLongDateAndTime(trayInfo.timestamp);
    const dateTimeStr = trayInfo.timestamp !== -1 ? strings.formatString(strings.singleTrayManagementPage__title, {
        date: dateTime['day'], 
        time: dateTime['time']
    }) : strings.singleTrayManagementPage__loadingTitle;

    const detections = useMemo(() => filterDetections(trayInfo.detections, editDict, !showDishes), [trayInfo.detections, editDict, showDishes]);

    const handleDetectionClick = useCallback((detection) => {
        setSelectedDetection(detection);
    }, [])

    const handleModalReassign = useCallback((recipe) => {
        const newEditDict = produce(editDict, draft => {
            draft[selectedDetection.id] = recipe;
        });

        setEditDict(newEditDict);
        setSelectedDetection(null);
    }, [editDict, selectedDetection]);

    const { search } = useLocation();
    const history = useHistory();

    const onSave = useCallback(async () => {
        const edits = [];
        
        for (const [id, recipe] of Object.entries(editDict)) {
            edits.push({
                'id': parseInt(id),
                'recipe_id': recipe.value
            });
        }

        try {
            await postDetectionEdits(trayInfo.site_id, props.match.params.id, edits);
            toastSuccess(strings.singleTrayManagementPage__toastSuccess__trayEdited);
            history.push({
                pathname: '/admin/tray-management',
                search: search
            });
        } catch (error) {
            handleAxiosError(error, {
                409: (e) => {
                    toastError(strings.singleTrayManagementPage__toastError__detectionAlreadyManaged);
                    history.push({
                        pathname: '/admin/tray-management',
                        search: search
                    });
                }
            }, strings.singleTrayManagementPage__toastError__genericPostDetectionEditsApiError);
        }
    }, [editDict, trayInfo.site_id, props.match.params.id, history, search]);

    const filters = useMemo(() => ({
        timestamp: trayInfo.timestamp,
        mode: 'filtered'
    }), [trayInfo.timestamp]);

    return (
        <>
            <Header/>
            <div className={css.pageContainer}>
                <h1>{dateTimeStr}</h1>
                <div className={css.pageContent}>
                    <div className={css.buttonBarContainer}>
                        <div className={css.halfButtonBar}>
                            <LinkWithQuery 
                                className={'btn btn-primary ' + css.myButton}
                                to={{pathname: '/admin/tray-management'}}
                            >
                                {strings.singleTrayManagementPage__backButtonLabel}
                            </LinkWithQuery>
                            <Button
                                className={css.myButton}
                                color="primary"
                                onClick={() => setShowDishes(!showDishes)}
                            >
                                {strings['singleTrayManagementPage__showDishesButtonLabel__state_' + showDishes.toString()]}
                            </Button>
                        </div>
                        <div className={css.halfButtonBar}>
                            <Button
                                className={css.myButton}
                                color="primary"
                                onClick={() => setEditDict({})}
                            >
                                {strings.singleTrayManagementPage__restoreButtonLabel}
                            </Button>
                            <Button 
                                className={css.myButton}
                                color="primary"
                                disabled={Object.keys(editDict).length === 0}
                                onClick={() => setSaveModalOpen(true)}
                            >
                                {strings.singleTrayManagementPage__saveButtonLabel}
                            </Button>
                        </div>
                    </div>
                    <WKTVisualizer
                        src={blobUrl}
                        areas={detections}
                        onBboxClick={handleDetectionClick}
                        fill={true}
                        showColorOnlyWhenHovering={true}
                    />
                    <div className={css.receiptsContainer}>
                        <div className={css.receiptColumn}>
                            <p>{strings.singleTrayManagementPage__oldReceiptLabel}</p>
                            <ListGroup>
                                {trayInfo.old_receipt.items?.map((r) => <ListGroupItem key={r.recipeId}>{r.description}</ListGroupItem>)}
                            </ListGroup>
                        </div>
                        <div className={css.receiptColumn}>
                            <p>{strings.singleTrayManagementPage__newReceiptLabel}</p>
                            <ListGroup>
                                {trayInfo.receipt.items?.map((r) => <ListGroupItem key={r.recipeId}>{r.description}</ListGroupItem>)}
                            </ListGroup>
                        </div>
                    </div>
                </div>
            </div>
            {trayInfo.site_id ? 
                <ReassignRecipeModal
                    isOpen={selectedDetection !== null}
                    toggle={() => setSelectedDetection(null)}
                    location={trayInfo.site_id}
                    onReassign={(recipe) => handleModalReassign(recipe)}
                    recipeFilters = {filters}
                    defaultSelected={selectedDetection?.recipe_id}/>
            : ''}
            <SaveModal
                isOpen={saveModalOpen}
                toggle={() => setSaveModalOpen(!saveModalOpen)}
                onSave={() => onSave()}
                prompt={strings.singleTrayManagementPage__saveModalPrompt}
            />
        </>
    );
}
