import CryptoJS from "crypto-js";
import { BASE_URL } from './config';
import { auth } from './index';
import axios from 'axios';
import { hashFile } from 'utils/fileHasher';


async function axiosGet(relativeURL, baseUrl = BASE_URL) {
    const authCfg = await auth.getAxiosAuthCfg();
    return axios.get(baseUrl + relativeURL, authCfg);
}
 
async function axiosPost(relativeURL, data, baseUrl = BASE_URL) {
    const authCfg = await auth.getAxiosAuthCfg();
    return axios.post(baseUrl + relativeURL, data, authCfg);
}

async function axiosPut(relativeURL, data, baseUrl = BASE_URL) {
    const authCfg = await auth.getAxiosAuthCfg();
    return axios.put(baseUrl + relativeURL, data, authCfg);
}

async function axiosDelete(relativeURL, baseUrl = BASE_URL) {
    const authCfg = await auth.getAxiosAuthCfg();
    return axios.delete(baseUrl + relativeURL, authCfg);
}

async function axiosGetBlob(relativeURL, baseUrl = BASE_URL) {
    const cfg = await auth.getAxiosAuthCfg();
    cfg['responseType'] = 'blob';
    return axios.get(baseUrl + relativeURL, cfg);
}

async function axiosGetBlobWithPost(relativeURL, data, baseUrl = BASE_URL) {
    const cfg = await auth.getAxiosAuthCfg();
    cfg['responseType'] = 'blob';
    return axios.post(baseUrl + relativeURL, data, cfg);
}

export function getUsers() {
    return axiosGet('/api/users/');
}

export function getUser(user_id) {
    return axiosGet('/api/users/' + user_id);
}

export function deleteUser(user_id) {
    return axiosDelete('/api/users/' + user_id + '/delete/');
}

export function createUser(user) {
    return axiosPost('/api/users/create/', user);
}

export function editUser(user_id, user) {
    return axiosPut('/api/users/' + user_id + '/update/', user);
}

export function sendForgotPassword(email) {
    return axiosPost('/api/users/forgot_password/', {email});
}

export function getStatistics(from, to, location) {
    const data = {
        "start_date": parseInt(from),
        "end_date": parseInt(to)
    };

    return axiosPost('/api/canteen/' + location + '/statistics/', data);
}

export function downloadExcelStatistics(from, to, location) {
    const data = {
        "start_date": parseInt(from),
        "end_date": parseInt(to)
    };

    return axiosGetBlobWithPost('/api/canteen/' + location + '/statistics_excel/', data);
}

export function getConfigCategories() {
    return axiosGet('/api/config/categories/');
}

export function getConfigCanteens() {
    return axiosGet('/api/config/canteens/');
}

export function getConfigSpecificToGenericMap() {
    return axiosGet('/api/config/specific_to_generic_map/');
}

export function getConfigSeasons() {
    return axiosGet('/api/config/seasons/');
}

export function getConfigModelTypes() {
    return axiosGet('/api/config/model_types/');
}

export function getConfigDeviceTypes() {
    return axiosGet('/api/config/device_types/');
}

export function getConfigFeatures() {
    return axiosGet('/api/config/features/');
}

export function getConfigRoles() {
    return axiosGet('/api/config/roles/');
}

export function getEmbeddingsInfo(location, season) {
    const url = '/api/canteen/' + location + '/embeddings_info/';

    const data = {}

    if (season) {
        data['season'] = season
    }

    return axiosPost(url, data);
}

export function getDetectionsToManage(location) {
    return axiosGet('/api/canteen/' + location + '/get_detections_manage/');
}

export function getSingleDetectionToManage(detection_id) {
    return axiosGet('/api/dashboard/get_detection_manage/' + detection_id);
}

export function postDetectionEdits(location, detection_id, edits) {
    const url = '/api/canteen/' + location + '/manage_detection/' + detection_id;
    return axiosPost(url, edits);
}

export function deleteEmbedding(location, recipe_id, embeddings_id, crop_id) {
    const url = '/api/canteen/' + location + '/delete_embedding/';

    const data = {
        recipe_id,
        embeddings_id,
        crop_id
    }

    return axiosPost(url, data);
}

export function reassignEmbedding(location, recipe_id, embeddings_id, crop_id, recipe_to_assign_id) {
    const url = '/api/canteen/' + location + '/reassign_embedding/';

    const data = {
        recipe_id,
        embeddings_id,
        crop_id,
        recipe_to_assign_id
    }

    return axiosPost(url, data);
}

export function addExistingEmbedding(location, embeddings_id, crop_id, recipe_to_assign_id) {
    const url = '/api/canteen/' + location + '/add_existing_embedding/';

    const data = {
        embeddings_id,
        crop_id,
        recipe_id: recipe_to_assign_id
    }

    return axiosPost(url, data);
}

export function getSingleEmbeddingInfo(location, recipe_id) {
    return axiosGet('/api/canteen/' + location + '/embeddings_info/' + recipe_id);
}

export function getCanteenRecipes(location, timestamp = null, mode = null, requestCategories = false) {
    let url = '/api/canteen/' + location + '/recipes/';
    
    if (timestamp) {
        url += timestamp + '/' + mode;
        if (requestCategories) {
            url += '?out_format=with-categories';
        }
    }

    return axiosGet(url);
}

export function getGalleryImages(start, end, from, to, location, category, recipe, errorFilter) {
    const url = '/api/canteen/' + location + '/images/' + start + '/' + end + '/';

    const data = {
        "start_date": parseInt(from),
        "end_date": parseInt(to),
        "category": category,
        "recipe": recipe,
        "error_filter": errorFilter
    };
    
    return axiosPost(url, data);
}

export function getAllRecipes() {
    return axiosGet('/api/config/recipes/');
}

export function getConfigSystemLogCategories() {
    return axiosGet('/api/config/system_logs_categories/');
}

export function getSystemLogs(date, category, username) {
    const url = '/api/systemlogs/';
    const data = {date, category, username};
    return axiosPost(url, data);
}

export function getDetectionDetails(detectionId, from, to, location, category, recipe, errorFilter) {
    const data = {
        'image_id': detectionId
    };

    if (from && to && category && recipe && errorFilter != null) {
        data['start_date'] = parseInt(from);
        data['end_date'] = parseInt(to);
        data['category'] = category;
        data['recipe'] = recipe;
        data['error_filter'] = errorFilter;
    }

    if (location) {
        return axiosPost('/api/canteen/' + location + '/inference_details/', data);
    } else {
        return axiosPost('/api/inference_details/', data);
    }
}

export function getSelectableDates(location) {
    return axiosGet('/api/canteen/' + location + '/selectable_dates');
}

export function getImage(url) {
    return axiosGetBlob(url, '');
}

export async function postModel(modelFile, labelFile, location, modelType, version) {
    const formData = new FormData();
    const modelHashPromise = hashFile(modelFile, CryptoJS.algo.SHA256);
    const labelHashPromise = labelFile !== null ? hashFile(labelFile, CryptoJS.algo.SHA256) : null;
    
    let modelHash = null;
    let labelHash = null;

    if (labelHashPromise === null) {
        modelHash = await modelHashPromise;
    } else {
        const hashes = await Promise.all([modelHashPromise, labelHashPromise]);
        modelHash = hashes[0];
        labelHash = hashes[1];
    }

    formData.append("canteen", location);
    formData.append("model_type", modelType);
    formData.append("version", version);
    formData.append("model_file", modelFile);
    formData.append("model_hash", modelHash);
    
    if (labelFile !== null) {
        formData.append("label_file", labelFile);
        formData.append("label_hash", labelHash);
    }

    return axiosPost('/api/upload_model/', formData);
}

export async function postAPK(apkFile, deviceType, version) {
    const formData = new FormData();
    const apkHash = await hashFile(apkFile, CryptoJS.algo.SHA256);

    formData.append("device_type", deviceType);
    formData.append("version", version);
    formData.append("apk_file", apkFile);
    formData.append("apk_hash", apkHash);
    
    return axiosPost('/api/upload_device/', formData);
}
