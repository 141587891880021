import { strings } from 'localization';
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap';

export default function SaveModal(props) {
    return (
        <Modal isOpen={props.isOpen} backdrop={true} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}>
                {strings.saveModal__title}
            </ModalHeader>
            <ModalBody>
                {props.prompt}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={props.onSave}>
                    {strings.saveModal__saveButtonLabel}
                </Button>
                <Button color="secondary" onClick={props.toggle}>
                    {strings.saveModal__cancelButtonLabel}
                </Button>
            </ModalFooter>
        </Modal>
    );
}
