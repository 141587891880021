import css from './AuthFooter.module.css';

const AuthFooter = () => {
  return (
    <>
      <footer className={css.footer}>
        <img
          alt="..."
          className={css.addforLogo}
          src={
            require("../../../assets/img/brand/addfor_logo_sm.png")
          }
        />
      </footer>
    </>
  );
};

export default AuthFooter;
