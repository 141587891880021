import FileInput from 'components/FileInput/FileInput';
import Header from 'components/Headers/Header';
import { ConfigContext } from 'context';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { toastError } from 'utils/utils';
import { toastSuccess } from 'utils/utils';
import { handleAxiosError } from 'utils/utils';
import Select from 'react-select';
import css from './APKUploadPage.module.css';
import { postAPK } from 'services/api';
import { strings } from 'localization';
import { AVAILABLE_CONFIGS } from 'hooks/useConfig';

export default function ModelUploadPage() {
    const [apkFile, setApkFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const apkFileInputRef = useRef(null);

    const config = useContext(ConfigContext);

    const requestConfigUpdate = config.requestConfigUpdate;
    useEffect(() => {
        requestConfigUpdate([AVAILABLE_CONFIGS.DEVICE_TYPES]);
    }, [requestConfigUpdate]);

    const [deviceType, setDeviceType] = useState(config.device_types[0]);
    const [version, setVersion] = useState('');

    async function saveChanges() {
        if (apkFile === null) {
            toastError(strings.apkUploadPage__toastError__missingApkFile);
            return;
        }

        if (version === null || version === '') {
            toastError(strings.apkUploadPage__toastError__missingVersion);
            return;
        }

        try {
            setUploading(true);
            await postAPK(apkFile, deviceType.value, version);
            apkFileInputRef.current.reset();
            setApkFile(null);
            setVersion('');
            setDeviceType(config.device_types[0]);
            toastSuccess(strings.apkUploadPage__toastSuccess__uploadSuccess);
        } catch (error) {
            handleAxiosError(error, {}, strings.apkUploadPage__toastError__genericApiError);
        } finally {
            setUploading(false);
        }
    }

    return (
        <>
            <Header />
            <div className={css.pageContainer}>
                <div className={css.formContainer}>
                    <Form>
                        <Row>
                            <Col md="4">
                                <FormGroup>
                                    <Label for='deviceTypeSelect'>{strings.apkUploadPage__deviceFieldLabel}</Label>
                                    <Select
                                        id='deviceTypeSelect'
                                        value={deviceType}
                                        onChange={(dt) => setDeviceType(dt)}
                                        options={config.device_types}
                                        placeholder={strings.apkUploadPage__deviceFieldPlaceholder}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="2">
                                <FormGroup>
                                    <Label for='versionInput'>{strings.apkUploadPage__versionFieldLabel}</Label>
                                    <Input
                                        id='versionInput'
                                        type='text'
                                        value={version}
                                        onChange={(e) => setVersion(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label for='apkFileInput'>{strings.apkUploadPage__apkFieldLabel}</Label>
                                    <FileInput
                                        ref={apkFileInputRef}
                                        id="apkFileInput"
                                        label={strings.apkUploadPage__apkButtonLabel}
                                        onChange={(file) => setApkFile(file)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <Button
                                        disabled={uploading}
                                        color="primary"
                                        onClick={() => saveChanges()}
                                    >{strings.apkUploadPage__saveButtonLabel}</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    );
}
