import Header from 'components/Headers/Header';
import { ConfigContext } from 'context';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { downloadExcelStatistics } from 'services/api';
import { getStatistics } from 'services/api';
import { downloadBlob } from 'utils/utils';
import { calculateDateAndTimeSpan } from 'utils/utils';
import { handleAxiosError } from 'utils/utils';
import Checkbox from 'components/Checkbox/Checkbox';
import './StatisticsPage.css';
import { strings } from 'localization';
import { useQueryFilters } from 'hooks/useQueryFilters';
import { AVAILABLE_CONFIGS } from 'hooks/useConfig';

async function loadStatistics(location, from, to, setStatistics, isCumulative) {
    if (isCumulative) {
        from = 0;
        to = 2147483647; // TODO: solve Y2038 bug
    }

    if (from === null || to === null || location === null) {
        return
    }

    try {
        const response = await getStatistics(from, to, location);
        setStatistics(response.data);
    } catch (error) {
        handleAxiosError(error, {}, strings.statisticsPage__toastError__genericApiError);
    }
}

function handleStatisticsDownload(location, from, to, isCumulative) {
    return async (e) => { 
        if (isCumulative) {
            from = 0;
            to = 2147483647; // TODO: solve Y2038 bug
        }

        try {
            const response = await downloadExcelStatistics(from, to, location);
            const filename = response.headers['content-disposition'].split('"')[1]
            downloadBlob(response.data, filename);        
        } catch (error) {
            handleAxiosError(error, {}, strings.statisticsPage__toastError__genericApiError);
        }
    }
}

function filterStatistics(statistics, filterZero, filterPf) {
    const newStats = [];

    for (const category of statistics) {
        let filteredRecipes = category.recipes.filter((recipe) => (!filterZero) || recipe.count > 0);
        filteredRecipes = filteredRecipes.filter((recipe) => (!filterPf) || !recipe.pf);

        if (filteredRecipes.length > 0) {
            newStats.push({
                ...category,
                recipes: filteredRecipes
            });
        }
    }

    return newStats;
}

function renderRecipeList(recipes) {
    return recipes.map((recipe) => (
        <ListGroupItem key={recipe['recipe_id']}>
            <div className='statistics-listGroupItemContent'>
                <span>{recipe['recipe_name'] + '   '}</span>
                <span>{recipe.count}</span>
            </div>
        </ListGroupItem>
    ));
}

function renderCategories(categories) {
    return categories.map((category) => (
        <div className="categoryContainer" key={category['category_id']}>
            <h1>{category['category_name']}</h1>
            <ListGroup>
                {renderRecipeList(category.recipes)}
            </ListGroup>
        </div>
    ));
}

export default function StatisticsPage() {
    const [statistics, setStatistics] = useState([]);
    const [zeroFilterCheck, setZeroFilterCheck] = useState(false);
    const [pfFilterCheck, setPfFilterCheck] = useState(false);

    const config = useContext(ConfigContext);

    const requestConfigUpdate = config.requestConfigUpdate;
    useEffect(() => {
        requestConfigUpdate([AVAILABLE_CONFIGS.CANTEENS]);
    }, [requestConfigUpdate]);

    const [{queryLocation, queryFrom, queryTo, queryEnableDateFilter}, ] = useQueryFilters();

    const isCumulative = !queryEnableDateFilter;

    const locationName = useMemo(() => {
        return config.canteens.filter((l) => l.value.toString() === queryLocation)[0].label;
    }, [config.canteens, queryLocation]);

    const dateAndTimeSpan = useMemo(() => {
        return calculateDateAndTimeSpan(queryFrom, queryTo);
    }, [queryFrom, queryTo]);

    useEffect(() => {
        loadStatistics(queryLocation, queryFrom, queryTo, setStatistics, isCumulative);
    }, [queryLocation, queryFrom, queryTo, setStatistics, isCumulative]);    

    const timeSpanStr = dateAndTimeSpan.from + ' - ' + dateAndTimeSpan.to;

    let title = locationName + ' - ';

    if (isCumulative) {
        title += strings.statisticsPage__cumulativeStatisticsTitle;
    } else {
        title += timeSpanStr
    }

    const filteredStatistics = useMemo(() => {
        return filterStatistics(statistics, zeroFilterCheck, pfFilterCheck)
    }, [statistics, zeroFilterCheck, pfFilterCheck])

    return (
        <>
            <Header/>
            <div className="statisticsPageContainer">
                <div className="dateAndTimeSpanContainer">
                    <h1>{title}</h1>
                </div>
                <div className="statisticsContainer">
                    <div className="statisticsFilterBar">
                        <div className="statisticsCheckboxContainer">
                            <Checkbox
                                id='zeroFilterCheckbox'
                                checked={zeroFilterCheck}
                                onChange={(val) => setZeroFilterCheck(val)}
                            />
                            <span>{strings.statisticsPage__showAllDishesCheckboxLabel}</span>
                        </div>
                        <div className="statisticsCheckboxContainer">
                            <Checkbox
                                id='pfFilterCheckbox'
                                checked={pfFilterCheck}
                                onChange={(val) => setPfFilterCheck(val)}
                            />
                            <span>{strings.statisticsPage__hidePFCheckboxLabel}</span>
                        </div>
                    </div>
                    
                    {
                        (statistics && filteredStatistics.length > 0) ?
                        <>
                            {renderCategories(filteredStatistics)}
                            <Button
                                className='backButton'
                                color="primary"
                                onClick={handleStatisticsDownload(queryLocation, queryFrom, queryTo, isCumulative)}
                            >
                                {strings.statisticsPage__exportButtonLabel}
                            </Button>
                        </>
                        :
                        <h3 style={{"alignSelf": "center"}}>{strings.statisticsPage__noStatisticsAvailable}</h3>
                    }
                    
                </div>
                
            </div>
        </>
    );
}
