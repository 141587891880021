import { usePrevious } from 'hooks/usePrevious';
import { useEffect, useState } from 'react';
import { getImage } from 'services/api';
import css from './ImageWithOverlay.module.css';

export default function ImageWithOverlay(props) {
    const [blobUrl, setBlobUrl] = useState('#');
    const [loading, setLoading] = useState(true);
    const previousSrc = usePrevious(props.src);
  
    useEffect(() => {
      async function fetchImage() {
        try {
          const response = await getImage(props.src);
          setBlobUrl(URL.createObjectURL(response.data));
          setLoading(false);
        } catch (error) {
          console.error(error);
        }
      }
  
      if (props.src !== previousSrc) {
        fetchImage();
      }
  
      return function cleanup() {
        URL.revokeObjectURL(blobUrl);
      }
    }, [props.src, blobUrl, previousSrc]);

    return (
      <>
        {loading ?
          <div className={css.skeletonLoader}></div>
        :
        <div className={css.container}>
            <img className={css.image} src={blobUrl} alt='' />
            <div className={css.overlay}>
                {props.children}
            </div>
        </div>
        }
      </>
    );
}