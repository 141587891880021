/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useLocation, Switch, Redirect, Route } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { useState, useEffect, useRef } from "react";
import routes from "routes.js";
import { ConfigContext } from "context";
import Spinner from "components/Spinner/Spinner";
import SearchFilterModal from "components/SearchFilterModal/SearchFilterModal";
import { useConfig } from "hooks/useConfig";
import { AVAILABLE_CONFIGS } from "hooks/useConfig";
import { isEmptyObj } from "utils/utils";

const Admin = (props) => {
  const mainContent = useRef(null);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  
  const [config, requestConfigUpdate] = useConfig([
    AVAILABLE_CONFIGS.CANTEENS,
    AVAILABLE_CONFIGS.SEASONS,
    AVAILABLE_CONFIGS.CATEGORIES,
    AVAILABLE_CONFIGS.RECIPES,
    AVAILABLE_CONFIGS.MODEL_TYPES,
    AVAILABLE_CONFIGS.DEVICE_TYPES,
    AVAILABLE_CONFIGS.FEATURES,
    AVAILABLE_CONFIGS.ROLES,
    AVAILABLE_CONFIGS.SYSTEM_LOGS_CATEGORIES,
    AVAILABLE_CONFIGS.SPECIFIC_TO_GENERIC_MAP
  ]);
  
  useEffect(() => {
    if (!isEmptyObj(config)) {
      setLoading(false);
    }
  }, [config]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);
  
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" && prop.isEnabled(config.features)) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path.split(':')[0]) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Addfor Industriale";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes.filter(route => route.layout === '/admin' && route.isEnabled(new Set(config.features)))}
        logo={{
          imgSrc: require("../assets/img/brand/nutray_white_logo.png"),
          imgSrcForWhiteBackground: require("../assets/img/brand/nutray_color_logo.png"),
          imgAlt: "...",
        }}
        toggleSearchModal={(e) => {e.preventDefault(); setSearchModalOpen(!searchModalOpen)}}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
          toggleSearchModal={(e) => {e.preventDefault(); setSearchModalOpen(!searchModalOpen)}}
        />
        {
          loading ?
          <Spinner></Spinner>
          :
          <ConfigContext.Provider value={{...config, requestConfigUpdate}}>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/admin/gallery" />
            </Switch>
            <SearchFilterModal
              isOpen={searchModalOpen}
              toggle={() => setSearchModalOpen(!searchModalOpen)}
            />
          </ConfigContext.Provider>
        }
      </div>
    </>
  );
};

export default Admin;
