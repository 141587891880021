
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col,
    FormFeedback
  } from "reactstrap";
import { auth } from "services/api";
import { toastError } from "utils/utils";
import { handleAxiosError } from "utils/utils";
import { strings } from "localization";
import css from './SetPasswordPage.module.css'
import { toastSuccess } from "utils/utils";
import PasswordStrengthMeter from "components/PasswordStrengthMeter/PasswordStrengthMeter";

  const SetPasswordPage = (props) => {
    const [password, setPassword] = useState('');
    const [passwordErrors, setPasswordErrors] = useState([]);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordErrors, setConfirmPasswordErrors] = useState([]);

    const history = useHistory();

    const handleSetPasswordBtnClick = useCallback(async (event) => {
      let error = false;

      if (password === '') {
        setPasswordErrors([strings.setPasswordPage__formError__missingPassword]);
        error = true;
      } else {
        setPasswordErrors([]);
      }

      if (confirmPassword === '') {
        setConfirmPasswordErrors([strings.setPasswordPage__formError__confirmPassword]);
        error = true;
      } else if (confirmPassword !== password) {
        setConfirmPasswordErrors([strings.setPasswordPage__formError__confirmedPasswordNotEqualToPassword]);
        error = true;
      } else {
        setConfirmPasswordErrors([]);
      }

      if (error) {
        return
      }

      try {
        await auth.setPassword(password, props.match.params.token);
        toastSuccess(strings.setPasswordPage__toastSuccess__passwordSet);
        history.push('/auth/login');
      } catch (error) {
        const handlers = {
          400: (e) => {
            const pswErrors = e.response.data.password;
            if (pswErrors && pswErrors.length > 0) setPasswordErrors([strings.formatString(strings["setPasswordPage__formError__" + pswErrors[0].error_code], pswErrors[0].vars)]);
          },
          403: (e) => {
            toastError(strings.setPasswordPage__toastError__wrongToken);
          }
        }
        handleAxiosError(error, handlers, strings.setPasswordPage__toastError__genericSetpswError);
      }
    }, [history, password, confirmPassword, props.match.params.token]);

    const handleKeyDown = useCallback((event) => {
      if (event.which === 13) {
        handleSetPasswordBtnClick(event);
      }
    }, [handleSetPasswordBtnClick]);

    useEffect(() => {
      document.addEventListener("keydown", handleKeyDown, false);

      return () => {
        document.removeEventListener("keydown", handleKeyDown, false);
      }
    }, [handleKeyDown]);

    return (
      <>
        <Col lg="5" md="7">
          <div className={css.addforLoginLogoContainer}>
            <img
              alt="..."
              className={css.nutrayLoginLogo}
              src={
                require("../../assets/img/brand/nutray_white_logo.png")
              }
            />
          </div>
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-muted text-center mt-2 mb-3">
                <h1>{strings.setPasswordPage__setPasswordFormTitle}</h1>
              </div>
              <Form role="form">
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={strings.setPasswordPage__passwordFieldLabel}
                      type="password"
                      autoComplete="current-password"
                      invalid={passwordErrors.length > 0}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <FormFeedback>
                      <span className="ml-1">{passwordErrors[0]}</span>
                    </FormFeedback>
                  </InputGroup>
                  <PasswordStrengthMeter password={password}/>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={strings.setPasswordPage__confirmPasswordFieldLabel}
                      type="password"
                      autoComplete="current-password"
                      invalid={confirmPasswordErrors.length > 0}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <FormFeedback>
                      <span className="ml-1">{confirmPasswordErrors[0]}</span>
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={(e) => handleSetPasswordBtnClick(e)}>
                    {strings.setPasswordPage__setPasswordButtonLabel}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  };
  
  export default SetPasswordPage;
