import Header from 'components/Headers/Header';
import css from './TrayManagementPage.module.css';
import TrayManagementInfoCard from 'components/TrayManagementInfoCard/TrayManagementInfoCard';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ConfigContext } from 'context';
import { AVAILABLE_CONFIGS } from 'hooks/useConfig';
import { useQueryFilters } from 'hooks/useQueryFilters';
import { strings } from 'localization';
import { getDetectionsToManage } from 'services/api';
import { handleAxiosError } from 'utils/utils';
import { usePollingEffect } from 'hooks/usePollingEffect';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const POLLING_INTERVAL = 15000;

function renderTrayManagementInfoCards(detectionsToCorrect, clickCallback) {
    return detectionsToCorrect.map((dtc) => (
        <TrayManagementInfoCard
            key={dtc.id}
            thumbnail_url={dtc.thumbnail_url}
            date={dtc.date}
            additions={dtc.insertions}
            deletions={dtc.deletions}
            onClick={() => clickCallback(dtc.id)}
        />
    ));    
}

export default function TrayManagementPage() {

    const context = useContext(ConfigContext);
    const { search } = useLocation();
    const history = useHistory();

    const requestConfigUpdate = context.requestConfigUpdate;
    useEffect(() => {
        requestConfigUpdate([AVAILABLE_CONFIGS.CANTEENS]);
    }, [requestConfigUpdate]);

    const [{queryLocation}, ] = useQueryFilters();

    const locationName = useMemo(() => {
        return context.canteens.filter((l) => l.value.toString() === queryLocation)[0].label;
    }, [context.canteens, queryLocation]);

    const handleCorrectButtonClick = useCallback((id) => {
        history.push({
            pathname: '/admin/tray-management/' + id,
            search: search,
        });
    }, [search, history]);


    const [detectionsToCorrect, setDetectionsToCorrect] = useState([]);

    const fetchDetectionsToManage = useCallback(async () => {
        try {
            const response = await getDetectionsToManage(queryLocation);
            setDetectionsToCorrect(response.data);
        } catch (error) {
            handleAxiosError(error, {}, strings.trayManagementPage__toastError__genericDetectionsToManageApiError);
        }
    }, [queryLocation]);

    usePollingEffect(fetchDetectionsToManage, POLLING_INTERVAL);
    

    return (
        <>
            <Header/>
            <div className={css.pageContainer}>
                <div className={css.pageContent}>
                    <h1 className={css.pageTitle}>{locationName}</h1>
                    <div className={css.cardContainer}>
                        { detectionsToCorrect?.length > 0 ?
                            renderTrayManagementInfoCards(detectionsToCorrect, handleCorrectButtonClick)
                        :
                            <p>{strings.trayManagementPage__noTraysToManage}</p>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}