import { strings } from 'localization';
import { toast } from 'react-toastify';

export function areSetsEqual(a, b) {
    return a.size === b.size && new Set([...a, ...b]).size === a.size;
}

export function isEmptyObj(obj) {
    for (var name in obj) {
        return false;
    }
    return true;
}

export function getTimestampInSeconds() {
    return Math.floor(Date.now() / 1000);
}

export class DefaultDict {
    constructor(defaultVal) {
        return new Proxy({}, {
        get: (target, name) => name in target ? target[name] : defaultVal
        })
    }
}

export function capitalizeWords(str) {
    const arr = str.split(' ');
    return arr.map(element => {
        return element.charAt(0).toUpperCase() + element.substring(1).toLowerCase();
    }).join(' ');
}

export function getDefaultFromAndToTimes() {
    const from = new Date();
    const to = new Date();
    from.setHours(0, 0, 0, 0);
    to.setHours(23, 45, 0, 0);
    return [from.getTime() / 1000, to.getTime() / 1000];
}

export function calculateDateAndTimeSpan(from, to) {
    const dateAndTimeSpan = {};
    from = new Date(parseInt(from) * 1000);
    to = new Date(parseInt(to) * 1000);

    const dateOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    }

    const timeOptions = {
        hour: '2-digit',
        minute: '2-digit'
    }

    const startDay = capitalizeWords(from.toLocaleDateString(strings.locale, dateOptions));
    const endDay = capitalizeWords(to.toLocaleDateString(strings.locale, dateOptions));

    dateAndTimeSpan['from'] = startDay + ' ' + from.toLocaleTimeString(strings.locale, timeOptions);
    dateAndTimeSpan['to'] = endDay + ' ' + to.toLocaleTimeString(strings.locale, timeOptions);

    return dateAndTimeSpan
}

export function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename || 'file';

    const handleClick = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            link.removeEventListener('click', handleClick);
        }, 1000);
    };

    link.addEventListener('click', handleClick, false);
    link.click();
}

export const delayedReturn = (ms, data) => new Promise(r => setTimeout(() => r(data), ms));

export function toastError(msg) {
    toast(msg, {
        "type": "error"
    });
}

export function toastSuccess(msg) {
    toast(msg, {
        "type": toast.TYPE.SUCCESS
    });
}

export function toastWarn(msg) {
    toast(msg, {
        "type": toast.TYPE.WARNING
    });
}

export function handleAxiosError(error, handlers, defaultMessage) {
    console.error(error);

    if (error.code === "ERR_NETWORK") {
        toastError(strings.global__toastError__axiosNetworkError);
        return
    }

    if (error.response) {
        const handler = handlers[error.response.status];
        if (handler) {
            handler(error);
        } else {
            toastError(defaultMessage);
        }
    } else {
        toastError(strings.global__toastError__axiosNetworkError);
    }
}

export function formatLongDateAndTime(timestamp) {
    const dateAndTime = {};
    timestamp = new Date(parseInt(timestamp) * 1000);

    const dateOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    }

    const timeOptions = {
        hour: '2-digit',
        minute: '2-digit'
    }

    dateAndTime['day'] = capitalizeWords(timestamp.toLocaleDateString(strings.locale, dateOptions));
    dateAndTime['time'] = timestamp.toLocaleTimeString(strings.locale, timeOptions);

    return dateAndTime
}
