import { strings } from "localization";
import { useEffect, useState } from "react";
import { Progress } from "reactstrap";
import zxcvbn from "zxcvbn";

const COLORS = [
    "#cd3301",
    "#ff9935",
    "#ffcc00",
    "#22b004",
    "#1b8f06"
]

export default function PasswordStrengthMeter(props) {
    const [zxcvbnResult, setZxcvbnResult] = useState({});
    
    useEffect(() => {
        const truncatedPsw = props.password.substr(0, 100);
        setZxcvbnResult(zxcvbn(truncatedPsw));
    }, [props.password]);

    console.log(zxcvbnResult);

    return (
        <Progress
            className="mt-1"
            value={zxcvbnResult.guesses_log10}
            max={11} 
            style={{height: "16px"}}
            barStyle={{backgroundColor: COLORS[zxcvbnResult.score]}}
        >
            <b>{strings["zxcvbn__strengthMessage__score_" + zxcvbnResult.score]} {zxcvbnResult.guesses_log10 >= 11 ? "👍" : ''}</b>
        </Progress>
    );
}