import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreateOrEditUserModal from 'components/CreateOrEditUserModal/CreateOrEditUserModal';
import DeleteModal from 'components/DeleteModal/DeleteModal';
import Header from 'components/Headers/Header';
import UserManagementTable from 'components/UserManagementTable/UserManagementTable';
import { strings } from 'localization';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import { deleteUser } from 'services/api';
import { createUser } from 'services/api';
import { getUser } from 'services/api';
import { editUser } from 'services/api';
import { getUsers } from 'services/api';
import { toastSuccess } from 'utils/utils';
import { toastError } from 'utils/utils';
import { handleAxiosError } from 'utils/utils';
import css from './UserManagementPage.module.css';

export const ACTIONS = {
    EDIT: 'edit',
    CREATE: 'create',
    DELETE: 'delete'
}

export default function UserManagementPage() {
    // TODO: tradurre
    
    const [users, setUsers] = useState([]);
    const [searchText, setSearchText] = useState('');

    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);

    const [userInitializationData, setUserInitializationData] = useState(null);

    const fetchUsers = useCallback(async () => {
        try {
            const response = await getUsers();
            setUsers(response.data);
        } catch (error) {
            handleAxiosError(error, {}, strings.userManagementPage__toastError__genericGetUserApiError);
        }
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const filteredUsers = useMemo(() => {
        return users.map((u, i) => [i, u]).filter((iu) => {
            const user = iu[1];
            const username = user.username.toLowerCase();
            const name = user.name.toLowerCase();
            const surname = user.surname.toLowerCase();
            const text = searchText.toLowerCase();
            return username.includes(text) || name.includes(text) || surname.includes(text);
        });
    }, [users, searchText]);

    const unselect = useCallback(() => {
        setSelectedUser(null);
        setSelectedAction(null);
    }, []);

    const select = useCallback((user, action) => {
        setSelectedUser(user);
        setSelectedAction(action);
    }, []);

    const handleDeleteUser = useCallback(async () => {
        const newUsers = users.filter((usr, i) => i !== selectedUser);

        try {
            await deleteUser(users[selectedUser].id);
            setUsers(newUsers);
            unselect();
            await fetchUsers();
            toastSuccess(strings.userManagementPage__toastSuccess__userDeleted);
        } catch (error) {
            const handlers = {
                400: (e) => {
                  toastError(strings[e.response.data.error_code]);
                }
            }
            handleAxiosError(error, handlers, strings.userManagementPage__toastError__genericDeleteUserApiError)
        }
    }, [users, selectedUser, unselect, fetchUsers]);

    const handleCreateUser = useCallback(async (user) => {
        try {
            await createUser(user);
            unselect();
            await fetchUsers();
            toastSuccess(strings.userManagementPage__toastSuccess__userCreated);
        } catch (error) {
            const handlers = {
                400: (e) => {
                  toastError(strings[e.response.data.error_code]);
                }
            }
            handleAxiosError(error, handlers, strings.userManagementPage__toastError__genericCreateUserApiError);
        }
    }, [fetchUsers, unselect]);

    const handleEditUser = useCallback(async (user) => {
        try {
            await editUser(users[selectedUser].id, user);
            unselect();
            await fetchUsers();
            toastSuccess(strings.userManagementPage__toastSuccess__userEdited);
        } catch (error) {
            const handlers = {
                400: (e) => {
                  toastError(strings[e.response.data.error_code]);
                }
            }
            handleAxiosError(error, handlers, strings.userManagementPage__toastError__genericEditUserApiError);
        }
    }, [fetchUsers, unselect, selectedUser, users]);

    const handleCreateOrEditUser = useCallback(async (user) => {
        if (selectedAction === ACTIONS.CREATE) {
            handleCreateUser(user);
        } else if (selectedAction === ACTIONS.EDIT) {
            handleEditUser(user);
        }
    }, [handleCreateUser, handleEditUser, selectedAction]);

    const handleEditButtonClick = useCallback(async (i) => {
        try {
            const user = (await getUser(users[i].id)).data;
            select(i, ACTIONS.EDIT);
            setUserInitializationData(user);
        } catch (error) {
            handleAxiosError(error, {}, strings.userManagementPage__toastError__genericGetUserApiError);
        }
    }, [users, select]);

    const handleOnUserInitialized = useCallback(() => {
        setUserInitializationData(null);
    }, []);

    return (
        <>
            <Header />
            <div className={css.container}>
                <Form className='mt-3'>
                    <Row>
                        <Col md="11">
                            <FormGroup>
                                <Input
                                    id='mngUsrSearchBar'
                                    type='text'
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    placeholder={strings.userManagementPage__searchFieldPlaceholder}
                                    spellCheck="false"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="1">
                            <FormGroup>
                                <Button id='addUserButton' color="primary" onClick={() => setSelectedAction(ACTIONS.CREATE)}>
                                    <FontAwesomeIcon icon="user-plus" />
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                
                <UserManagementTable
                    users={filteredUsers}
                    onDelete={(i) => select(i, ACTIONS.DELETE)}
                    onEdit={(i) => handleEditButtonClick(i)}
                />
            </div>
            <DeleteModal
                isOpen={selectedUser !== null && selectedAction === ACTIONS.DELETE}
                toggle={() => unselect()}
                onDelete={() => handleDeleteUser()}
                prompt={strings.userManagementPage__deleteModalPrompt}
            />
            <CreateOrEditUserModal
                isOpen={selectedAction === ACTIONS.CREATE || (selectedAction === ACTIONS.EDIT && selectedUser !== null)}
                mode={new Set([ACTIONS.CREATE, ACTIONS.EDIT]).has(selectedAction) ? selectedAction : ACTIONS.CREATE}
                toggle={() => unselect()}
                onConfirm={(usr) => handleCreateOrEditUser(usr)}
                initializationData={userInitializationData}
                onUserInitialized={handleOnUserInitialized}
            ></CreateOrEditUserModal>
        </>
    );
}
