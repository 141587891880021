import css from './Checkbox.module.css';

export default function Checkbox(props) {
    return (
        <label className={css.container}>
            <input
                id={props.id}
                className={css.input}
                type="checkbox"
                onChange={(e) => props.onChange(e.target.checked)}
                checked={props.checked}
                disabled={props.disabled}
            />
            <span className={css.checkmark}></span>
        </label>
    );
}