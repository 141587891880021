import { BASE_URL } from './config';
import axios from 'axios';
import { Mutex, E_CANCELED } from 'async-mutex';
import { getTimestampInSeconds } from 'utils/utils';

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

class Auth {

    constructor() {
        this.token_refresh_mutex = new Mutex();
    }

    async login(username, password) {
        const userData = {
            username,
            password
        }

        const response = await axios.post(BASE_URL + '/api/auth/token/', userData);

        localStorage.refresh = response.data.refresh;
        localStorage.access = response.data.access;
    }

    async changePassword(oldPassword, newPassword) {
        const authCfg = await this.getAxiosAuthCfg();

        const data = {
            'old_password': oldPassword,
            'new_password': newPassword
        }

        await axios.put(BASE_URL + '/api/auth/change_password/', data, authCfg);
    }

    async setPassword(newPassword, token) {
        const data = {
            password: newPassword
        }
        await axios.post(BASE_URL + '/api/users/setpsw/' + token, data);
    }

    async logout() {
        const authCfg = await this.getAxiosAuthCfg();
        
        const data = {
            'refresh_token': localStorage.refresh
        }

        await axios.post(BASE_URL + '/api/auth/logout/', data, authCfg);
        localStorage.removeItem('refresh');
        localStorage.removeItem('access');
    }

    isLoggedIn() {
        return (localStorage.refresh && localStorage.access);
    }

    getUsername() {
        if (!localStorage.access) return null;
        return parseJwt(localStorage.access).name;
    }

    async getAxiosAuthCfg() {
        if (!this.isLoggedIn()) {
            return {}
        }

        let canceled = false;
        let release = () => {};

        try {
            release = await this.token_refresh_mutex.acquire();
        } catch (e) {
            if (e === E_CANCELED) {
                canceled = true;
            }
        }

        if (!canceled) {
            try {
                const jwt = parseJwt(localStorage.access);
                const timeMargin = jwt['access_token_lifetime'] * 0.20
                const currTime = getTimestampInSeconds();

                if (currTime + timeMargin > jwt.exp) {
                    // REFRESH
                    console.log('refreshing access token');
                    const data = {
                        'refresh': localStorage.refresh
                    };

                    const response = await axios.post(BASE_URL + '/api/auth/token/refresh/', data);

                    localStorage.refresh = response.data.refresh;
                    localStorage.access = response.data.access;
                    this.token_refresh_mutex.cancel();
                }
            } finally {
                release();
            }
        }

        return {
            headers: { Authorization: `Bearer ${localStorage.access}` }
        }
    }
}

const auth = new Auth();

axios.interceptors.response.use(
    function (response) {
        return response
    }, 
    function (error) {
        if (error.response.status === 401) {
            if (error.response.data.detail !== 'No active account found with the given credentials') {
                window.location.href = '/auth/login';
            }
        }
        return Promise.reject(error);
    }
);

export default auth;
