import { forwardRef, useState, useImperativeHandle, useRef } from 'react';
import css from './FileInput.module.css';

function formatFileName(name, maxlenhalf=7) {
    if (name.length > maxlenhalf * 2) {
        return name.substr(0, maxlenhalf) + '...' + name.substr(-maxlenhalf);
    }

    return name;
}

export default forwardRef((props, ref) => {
    const [fileName, setFileName] = useState('');
    const inputTypeFile = useRef(null);

    function reset() {
        console.log('resetting');
        setFileName('');
        inputTypeFile.current.value = '';
    }

    useImperativeHandle(ref, () => ({
        reset
    }));
    
    function onChange(e) {
        setFileName(e.target.files[0].name);
        props.onChange(e.target.files[0]);
    }

    return (
        <div className={css.container}>
            <label className={"btn btn-primary " + css.button} htmlFor={props.id}>
                {props.label}
                <input
                    ref={inputTypeFile}
                    className={css.fileInput}
                    id={props.id}
                    type="file"
                    onChange={(e) => onChange(e)}
                />
            </label>
            <span title={fileName}>{formatFileName(fileName)}</span>
        </div>
    );
});