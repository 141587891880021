import Header from "components/Headers/Header";
import PasswordStrengthMeter from "components/PasswordStrengthMeter/PasswordStrengthMeter";
import { strings } from "localization";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { auth } from "services/api";
import { handleAxiosError } from "utils/utils";
import { toastSuccess } from "utils/utils";
import './ChangePasswordPage.css';


export default function ChangePasswordPage(props) {
    const [oldPassword, setOldPassword] = useState('');
    const [oldPasswordErrors, setOldPasswordErrors] = useState([]);
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordErrors, setNewPasswordErrors] = useState([]);
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [confirmedPasswordErrors, setConfirmedPasswordErrors] = useState([]);
    const history = useHistory();

    const handleChangePasswordBtnClick = useCallback(async (event) => {
      let error = false;

      if (oldPassword === '') {
        setOldPasswordErrors([strings.changePasswordPage__formError__missingOldPassword]);
        error = true;
      } else {
        setOldPasswordErrors([]);
      }

      if (newPassword === '') {
        setNewPasswordErrors([strings.changePasswordPage__formError__missingNewPassword]);
        error = true;
      } else if (newPassword === oldPassword) {
        setNewPasswordErrors([strings.changePasswordPage__formError__newPasswordEqualToOldOne]);
        error = true;
      } else {
        setNewPasswordErrors([]);
      }

      if (confirmedPassword === '') {
        setConfirmedPasswordErrors([strings.changePasswordPage__formError__missingConfirmedPassword]);
        error = true;
      } else if (confirmedPassword !== newPassword) {
        setConfirmedPasswordErrors([strings.changePasswordPage__formError__confirmedPasswordDifferentFromNewOne]);
        error = true;
      } else {
        setConfirmedPasswordErrors([]);
      }

      if (error) {
        return
      }

      try {
        await auth.changePassword(oldPassword, newPassword);
        toastSuccess(strings.changePasswordPage__toastSuccess__passwordChanged);
        history.push({ pathname: '/admin/gallery', search: history.location.search });
      } catch (error) {
        const handlers = {
          400: (e) => {
            if (e.response.data.old_password) setOldPasswordErrors([strings[e.response.data.old_password]]);
            const newPswErrors = e.response.data.new_password;
            if (newPswErrors && newPswErrors.length > 0) setNewPasswordErrors([strings.formatString(strings["changePasswordPage__formError__" + newPswErrors[0].error_code], newPswErrors[0].vars)]);
          }
        }
        handleAxiosError(error, handlers, strings.changePasswordPage__toastError__genericApiError);
      }
    }, [confirmedPassword, history, oldPassword, newPassword]);
    
    const handleKeyDown = useCallback((event) => {
      if (event.which === 13) {
        handleChangePasswordBtnClick(event);
      }
    }, [handleChangePasswordBtnClick]);

    useEffect(() => {
      document.addEventListener("keydown", handleKeyDown, false);

      return () => {
        document.removeEventListener("keydown", handleKeyDown, false);
      }
    }, [handleKeyDown]);

    return (
        <>
        <Header />
        <div className="changePasswordContainer">
          <Card className="bg-secondary shadow border-0 changePasswordCard">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-muted text-center mt-2 mb-3">
                <h1>{strings.changePasswordPage__changePasswordFormTitle}</h1>
              </div>
              <Form role="form">
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={strings.changePasswordPage__oldPasswordFieldPlaceholder}
                      type="password"
                      autoComplete="current-password"
                      invalid={oldPasswordErrors.length > 0}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <FormFeedback>
                      <span className="ml-1">{oldPasswordErrors[0]}</span>
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={strings.changePasswordPage__newPasswordFieldPlaceholder}
                      type="password"
                      autoComplete="new-password"
                      invalid={newPasswordErrors.length > 0}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <FormFeedback>
                      <span className="ml-1">{newPasswordErrors[0]}</span>
                    </FormFeedback>
                  </InputGroup>
                  <PasswordStrengthMeter password={newPassword}/>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={strings.changePasswordPage__confirmPasswordFieldPlaceholder}
                      type="password"
                      autoComplete="new-password"
                      invalid={confirmedPasswordErrors.length > 0}
                      onChange={(e) => setConfirmedPassword(e.target.value)}
                    />
                    <FormFeedback>
                      <span className="ml-1">{confirmedPasswordErrors[0]}</span>
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={(e) => handleChangePasswordBtnClick(e)}
                  >
                    {strings.changePasswordPage__changePasswordButtonLabel}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
        </>
    );
}
