import css from './WKTVisualizer.module.css';
import { useEffect, useMemo, useState } from 'react';
import { parse as parseWkt } from 'wkt';

const COLORS = [
    "#ff0000",
    "#0000ff",
    "#ffa200",
    "#00fff2",
    "#4800ff",
    "#f700ff",
    "#126665",
    "#705004",
];

const ORIENTATION = {
    CLOCKWISE: -1,
    COUNTER_CLOCKWISE: 1
};

const DISH_ID = 'dish';
const DISH_PADDING_PX = 25;

function generatePaddedBboxPath(polygons, padding) {
    let [xMin, yMin, xMax, yMax] = calculateBoundingBox(polygons);
    xMin -= padding;
    yMin -= padding;
    xMax += padding;
    yMax += padding;
    return `M ${xMin} ${yMin} L ${xMax} ${yMin} L ${xMax} ${yMax} L ${xMin} ${yMax} Z`;
}

function generatePath(polygons, padding) {
    let path = '';

    if (padding > 0) {
        return generatePaddedBboxPath(polygons, padding);
    }

    for (const polygon of polygons) {
        for (const boundaryCoords of polygon) {
            path += ' ';

            for (let i = 0; i < boundaryCoords.length; i++) {
                if (i === 0) {
                    path += 'M ';
                } else {
                    path += 'L ';
                }
                path += boundaryCoords[i][0] + ' ';
                path += boundaryCoords[i][1] + ' ';
            }

            path += 'Z';
        }
    }

    return path;
}

function calculateBoundingBox(polygons) {
    let xMin = Number.MAX_SAFE_INTEGER;
    let yMin = Number.MAX_SAFE_INTEGER;
    let xMax = 0;
    let yMax = 0;

    for (const polygon of polygons) {
        for (const [x, y] of polygon[0]) {
            if (x > xMax) xMax = x;
            if (x < xMin) xMin = x;
            if (y > yMax) yMax = y;
            if (y < yMin) yMin = y;
        }
    }

    return [xMin, yMin, xMax, yMax]
}

function calculateBoundingBoxCenter(polygons) {
    const [xMin, yMin, xMax, yMax] = calculateBoundingBox(polygons);
    const x = (xMin + xMax) / 2;
    const y = (yMin + yMax) / 2;
    return [x, y];
}

function renderPaths(areas, onBboxClick, fill, showColorOnlyWhenHovering) {
    const paths = [];
    const dishPaths = [];

    let index = 0;

    for (const area of areas) {
        let strokeColor = 'black';
        let strokeDasharray = "";
        let padding = 0;
        let listToUse = paths;
        let fillTransparency = fill ? '55' : '';

        let onClick = () => {};
        let style = {};
        let classN = showColorOnlyWhenHovering ? css.opacityOnHover : '';

        if (onBboxClick) {
            onClick = () => onBboxClick(area);
            style = {'cursor': 'pointer'};
        }

        if (area.style?.stroke) {
            strokeColor = area.style.stroke;
        }

        if (area['recipe_id'] === DISH_ID) {
            strokeColor = 'blue';
            strokeDasharray = '25 25';
            padding = DISH_PADDING_PX;
            fillTransparency = '';
            onClick = () => {};
            style = {};
            classN = '';
            listToUse = dishPaths;
        }

        listToUse.push(
            <path
                key={index}
                className={classN}
                d={generatePath(area.polygons, padding)}
                fill={area.color + fillTransparency}
                stroke={strokeColor}
                strokeDasharray={strokeDasharray}
                strokeWidth="0.5%"
                onClick={onClick}
                style={style}
            ></path>
        );

        index += 1;
    }

    return dishPaths.concat(paths);
}

function renderTexts(areas, onBboxClick) {
    return areas.map((area, index) => {
        let [x, y] = calculateBoundingBoxCenter(area.polygons);

        let onClick = () => {};
        let style = {};

        if (onBboxClick) {
            onClick = () => onBboxClick(area);
            style = {'cursor': 'pointer'};
        }

        if (area['recipe_id'] === DISH_ID) {
            const [,yMin,,] = calculateBoundingBox(area.polygons);
            y = yMin - 20 - DISH_PADDING_PX;
            onClick = () => {};
            style = {};
        }

        return (
            <text
                key={index}
                filter="url(#solid)"
                x={x}
                y={y}
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize="1.33em"
                fontWeight="bold"
                fill="white"
                onClick={onClick}
                style={style}
            >{area['recipe_name']}</text>
        );
    });
}

function detectOrientation(coords) {
    let total = 0;
    for (let i = 0; i < coords.length; i++) {
        const [x1, y1] = coords[i];
        const [x2, y2] = coords[(i + 1) % coords.length];
        total += (x2 - x1) * (y2 + y1);
    }

    if (total > 0) {
        return ORIENTATION.COUNTER_CLOCKWISE;
    }
    
    return ORIENTATION.CLOCKWISE;
}

function addGeoJSONFields(areas, width, height, fill) {
    areas = [...areas]
    let colorIndex = 0;
    for (let i = 0; i < areas.length; i++) {
        let area = {...areas[i]};
        const geojson = parseWkt(area.wkt);

        if (geojson.type !== 'Polygon' && geojson.type !== 'MultiPolygon') {
            throw new Error("Accepted geometry types are Polygon and MultiPolygon");
        }

        let polygons = geojson.coordinates;
        if (geojson.type === 'Polygon') {
            polygons = [polygons]
        }

        const newPolygons = [];
        
        for (const polygon of polygons) {
            const newPolygon = [];
            const firstIter = true;

            for (const boundaryCoords of polygon) {
                const newBoundary = [];

                let desiredOrientation = ORIENTATION.COUNTER_CLOCKWISE;
                if (firstIter) {
                    desiredOrientation = ORIENTATION.CLOCKWISE;
                }

                const orientation = detectOrientation(boundaryCoords);
                
                if (orientation !== desiredOrientation) {
                    boundaryCoords.reverse();
                }

                for (const [x, y] of boundaryCoords) {
                    newBoundary.push([
                        x * (width - 1),
                        y * (height - 1)
                    ]);
                }

                newPolygon.push(newBoundary);
            }

            newPolygons.push(newPolygon);
        }
        
        area['polygons'] = newPolygons;
        if (fill && (area['recipe_id'] !== DISH_ID)) {
            area['color'] = COLORS[colorIndex % COLORS.length];
            colorIndex += 1;
        } else {
            area['color'] = 'transparent';
        }
        areas[i] = area;
    }

    return areas;
}

export default function WKTVisualizer(props) {
    const [imgW, setImgW] = useState(0);
    const [imgH, setImgH] = useState(0);

    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            setImgW(img.naturalWidth);
            setImgH(img.naturalHeight);
        }
        img.src = props.src;
    }, [props.src]);

    const areas = useMemo(
        () => addGeoJSONFields(props.areas, imgW, imgH, props.fill),
        [props.areas, imgW, imgH, props.fill]);

    const viewBox = "0 0 " + imgW + " " + imgH;
    const paths = renderPaths(areas, props.onBboxClick, props.fill, props.showColorOnlyWhenHovering);
    const texts = renderTexts(areas, props.onBboxClick);

    return (
        <svg viewBox={viewBox}>
            <defs>
                <filter x="0" y="0" width="1" height="1" id="solid">
                <feFlood floodColor="#00000055" result="bg" />
                <feMerge>
                    <feMergeNode in="bg"/>
                    <feMergeNode in="SourceGraphic"/>
                </feMerge>
                </filter>
            </defs>

            <image href={props.src} width="100%" height="100%" alt=""/>
            <g fillRule="evenodd">
                {paths}
            </g>
            {texts}
        </svg>
    );
}