import CryptoJS from "crypto-js";

var lastOffset = 0;
var previous = [];

export function hashFile(file, hashAlgo) {
    return new Promise((resolve, reject) => {
        var counter = 0;
        var isFirstError = true;
        hashAlgo = hashAlgo.create();

        loading(file,
            (data) => {
                var wordBuffer = CryptoJS.lib.WordArray.create(data);
                hashAlgo.update(wordBuffer);
                counter += data.byteLength;
            },
            (data) => {
                var hashed = hashAlgo.finalize().toString();
                resolve(hashed);
            },
            (error) => {
                if (isFirstError) {
                    isFirstError = false;
                    reject(error);
                }
            }
        );
    });
}

function loading(file, callbackProgress, callbackFinal, callbackError) {
    var chunkSize  = 1024*1024; // bytes
    var offset     = 0;
    var size=chunkSize;
    var partial;
    var index = 0;

    if(file.size===0){
        callbackFinal();
    }
    while (offset < file.size) {
        partial = file.slice(offset, offset+size);
        var reader = new FileReader();
        reader.size = chunkSize;
        reader.offset = offset;
        reader.index = index;
        reader.onload = function(evt) {
            callbackRead(this, file, evt, callbackProgress, callbackFinal);
        };
        reader.onerror = callbackError;
        reader.readAsArrayBuffer(partial);
        offset += chunkSize;
        index += 1;
    }
}

function callbackRead(reader, file, evt, callbackProgress, callbackFinal){


    if(lastOffset !== reader.offset){
        // not of order chunk: put into buffer
        previous.push({ offset: reader.offset, size: reader.size, result: reader.result});
        return;
    }

    function parseResult(offset, size, result) {
        lastOffset = offset + size;
        callbackProgress(result);
        if (offset + size >= file.size) {
            lastOffset = 0;
            callbackFinal();
        }
    }

    // in order chunk
    parseResult(reader.offset, reader.size, reader.result);

    // check previous buffered chunks
    var buffered = [{}]
    while (buffered.length > 0) {
        buffered = [];
        for (const item of previous) {
            if (item.offset === lastOffset) {
                buffered.push(item);
            }
        }

        buffered.forEach(function (item) {
            parseResult(item.offset, item.size, item.result);
            previous.splice(previous.indexOf(item), 1);
        })
    }
}
