import Gallery from "components/Gallery/Gallery";
import Header from "components/Headers/Header";
import Pagination from "components/Pagination/Pagination.js";
import { ConfigContext } from "context";
import { useCategories } from "hooks/useCategories";
import { AVAILABLE_CONFIGS } from "hooks/useConfig";
import { useQueryFilters } from "hooks/useQueryFilters";
import { strings } from "localization";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { getGalleryImages } from "services/api";
import { ALL_RECIPES } from "utils/constants";
import { handleAxiosError } from "utils/utils";
import { calculateDateAndTimeSpan } from "utils/utils";
import './GalleryPage.css';


export default function GalleryPage() {
    const [numItems, setNumItems] = useState(0);
    const [images, setImages] = useState([]);
    const [perPage, ] = useState(12);
    const [surroundingButtons, ] = useState(1);

    const pagination = useRef(null);

    const context = useContext(ConfigContext);

    const requestConfigUpdate = context.requestConfigUpdate;
    useEffect(() => {
        requestConfigUpdate([AVAILABLE_CONFIGS.CANTEENS, AVAILABLE_CONFIGS.RECIPES]);
    }, [requestConfigUpdate]);

    const categories = useCategories();

    const [{queryLocation, queryCategory, queryRecipe,
            queryFrom, queryTo, queryPage,
            queryErrorFilter, queryEnableDateFilter}, { setQueryPage } ] = useQueryFilters();

    const locationName = useMemo(() => {
        return context.canteens.filter((l) => l.value.toString() === queryLocation)[0].label;
    }, [context.canteens, queryLocation]);

    const categoryName = useMemo(() => {
        return categories.filter((c) => c.value.toString() === queryCategory)[0].label;
    }, [categories, queryCategory]);

    const recipeName = useMemo(() => {
        const newRecipes = [...context.recipes];
        newRecipes.push({
            recipe_id: ALL_RECIPES.value,
            recipe_name: ALL_RECIPES.label
        });
        return ' - ' + newRecipes.filter((r) => r.recipe_id === queryRecipe)[0].recipe_name;
    }, [context.recipes, queryRecipe]);

    const dateAndTimeSpan = useMemo(() => {
        return calculateDateAndTimeSpan(queryFrom, queryTo);
    }, [queryFrom, queryTo]);
    
    let dateAndTimeSpanStr = ' - ' + dateAndTimeSpan.from + ' - ' + dateAndTimeSpan.to;

    if (!queryEnableDateFilter) {
        dateAndTimeSpanStr = '';
    }

    useEffect(() => {
        pagination.current.goToPage(queryPage); 
    }, [queryPage]);

    useEffect(() => {
        async function fetchImg() {
            let start = perPage * (queryPage - 1);
            let end = perPage * (queryPage - 1) + perPage;

            try {
                const actualQueryFrom = queryEnableDateFilter ? queryFrom : 1;
                const actualQueryTo = queryEnableDateFilter ? queryTo : 2147483647; // TODO: solve Y2038 bug

                const galleryImages = (await getGalleryImages(
                    start, end, actualQueryFrom, actualQueryTo, queryLocation, queryCategory, queryRecipe, queryErrorFilter 
                )).data;

                const images = galleryImages.images.slice();

                for (const image of images) {
                    image['link'] = {
                        pathname: '/admin/details/' + image.image_id,
                        state: {
                            'from': queryFrom,
                            'to': queryTo,
                            'location': queryLocation,
                            'category': queryCategory,
                            'recipe': queryRecipe,
                            'errorFilter': queryErrorFilter,
                            'enableDateFilter': queryEnableDateFilter,
                            perPage
                        }
                    }
                }

                setNumItems(galleryImages.count);
                setImages(images);
            } catch (error) {
                handleAxiosError(error, {}, strings.galleryPage__toastError__genericApiError);
            }
        }
        fetchImg();
    }, [perPage, queryCategory, queryLocation, queryFrom, queryTo, queryPage, queryErrorFilter, queryRecipe, queryEnableDateFilter]);
    
    function onPageChange(start, end) {
        setQueryPage(1 + Math.floor(start / perPage));
    }

    return (
        <>
        <Header />
        <div className="galleryPageContainer">
          <div className="dateAndTimeSpanContainer">
            <h1>{locationName} {dateAndTimeSpanStr} - <span className='galleryPageCategory'>{categoryName}{recipeName}</span></h1>
          </div>
          <Gallery images={images}/>
          <Pagination
            ref={pagination}
            numItems={numItems}
            perPage={perPage}
            surroundingButtons={surroundingButtons}
            onPageChange={(start, end) => onPageChange(start, end)}
            useKeyboard={true}
          ></Pagination>
        </div>
      </>
    );
}
