import { strings } from 'localization';
import { useCallback } from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap';
import { toastError } from 'utils/utils';
import { toastSuccess } from 'utils/utils';

export default function LogModal(props) {

    const handleCopy = useCallback(async (log) => {
        try {
            await navigator.clipboard.writeText(log.message);
            toastSuccess(strings.logModal__toastSuccess__copied);
            props.toggle();
        } catch (error) {
            toastError(strings.logModal__toastError__copyError);
            console.error(error);
        }
    }, [props]);

    return (
        <Modal isOpen={props.isOpen} backdrop={true} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}>
                {strings.logModal__title}
            </ModalHeader>
            <ModalBody>
                <textarea
                    className="form-control"
                    style={{"resize": "none"}}
                    rows="8"
                    defaultValue={props.log?.message}
                    readOnly
                />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => handleCopy(props.log)}>
                    {strings.logModal__copyButtonLabel}
                </Button>
                <Button color="secondary" onClick={props.toggle}>
                    {strings.logModal__closeButtonLabel}
                </Button>
            </ModalFooter>
        </Modal>
    );
}
