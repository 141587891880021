import { useEffect, useRef } from "react";

export function usePollingEffect(
    asyncCallback,
    interval = 10_000,
    onCleanUp = null
) {
    const timeoutIdRef = useRef(null);
    
    useEffect(() => {
        let _stopped = false;

        async function pollingCallback() {
            try {
                await asyncCallback();
            } finally {
                timeoutIdRef.current = !_stopped && setTimeout(pollingCallback, interval);
            }
        }

        pollingCallback();

        return () => {
            _stopped = true;
            clearTimeout(timeoutIdRef.current);
            if (onCleanUp) onCleanUp();
        }
    }, [asyncCallback, onCleanUp, interval])
}
