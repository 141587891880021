import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { strings } from 'localization';
import { Button, Table } from 'reactstrap';
import css from "./UserManagementTable.module.css";


function renderRoles(roles) {
    return (
        <span title={roles.join('\n')}>{roles.length}</span>
    );
}

function renderLastActivity(timestamp) {
    const last_activity_date_obj = new Date(timestamp * 1000);
    const last_activity = timestamp !== null ? last_activity_date_obj.toLocaleDateString(strings.locale) : strings.userManagementTable__lastActivityNone
    const last_activity_detail = timestamp !== null ? last_activity_date_obj.toLocaleTimeString(strings.locale) : ''

    return (
        <span title={last_activity_detail}>{last_activity}</span>
    );
}

function renderRow(user, onDelete, onEdit) {
    

    return (
        <tr key={user.id}>
            <td className={css.tableCell}>{user.username}</td>
            <td className={css.tableCell}>{user.name}</td>
            <td className={css.tableCell}>{user.surname}</td>
            <td className={css.tableCell}>{renderRoles(user.roles)}</td>
            <td className={css.tableCell}>{renderLastActivity(user.last_activity)}</td>
            <td className="text-right">
                <Button color="warning" onClick={onEdit}>
                <FontAwesomeIcon icon="user-pen" />
                </Button>
                <Button color="danger" onClick={onDelete}>
                    <FontAwesomeIcon icon="trash" />
                </Button>
            </td>
        </tr>
    );
}

function renderRows(users, onDelete, onEdit) {
    return users.map((iu) => renderRow(iu[1], () => onDelete(iu[0]), () => onEdit(iu[0])));
}

export default function UserManagementTable(props) {
    return (
        <Table className='align-items-center' responsive>
            <thead className='thead-light'>
                <tr>
                    <th scope="col">{strings.userManagementTable__usernameColumnName}</th>
                    <th scope="col">{strings.userManagementTable__nameColumnName}</th>
                    <th scope="col">{strings.userManagementTable__surnameColumnName}</th>
                    <th scope="col">{strings.userManagementTable__roleColumnName}</th>
                    <th scope="col">{strings.userManagementTable__lastActivityColumnName}</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {renderRows(props.users, props.onDelete, props.onEdit)}
            </tbody>
        </Table>
    );
}
