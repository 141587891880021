import Checkbox from "components/Checkbox/Checkbox";
import Header from "components/Headers/Header";
import { ConfigContext } from "context";
import { AVAILABLE_CONFIGS } from "hooks/useConfig";
import { useQueryFilters } from "hooks/useQueryFilters";
import { strings } from "localization";
import { useContext, useEffect, useMemo, useState } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { getCanteenRecipes } from "services/api";
import { handleAxiosError } from "utils/utils";
import css from './DailyMenuPage.module.css';

function renderRecipeList(recipes) {
    return recipes.map((recipe) => (
        <ListGroupItem key={recipe['recipe_id']}>
            <div className={css.listGroupItemContent}>
                <span>{recipe['recipe_name'] + '   '}</span>
                <span>{recipe.count}</span>
            </div>
        </ListGroupItem>
    ));
}

function renderCategories(categories) {
    return categories.map((category) => (
        <div key={category['category_id']}>
            <h1>{category['category_name']}</h1>
            <ListGroup>
                {renderRecipeList(category.recipes)}
            </ListGroup>
        </div>
    ));
}

function filterMenu(menu, filterPf) {
    const newMenu = [];

    for (const category of menu) {
        const filteredRecipes = category.recipes.filter((recipe) => (!filterPf) || !recipe.pf);

        if (filteredRecipes.length > 0) {
            newMenu.push({
                ...category,
                recipes: filteredRecipes
            });
        }
    }

    return newMenu;
}

export default function DailyMenuPage() {

    const config = useContext(ConfigContext);
    const [pfFilterCheck, setPfFilterCheck] = useState(false);

    const requestConfigUpdate = config.requestConfigUpdate;
    useEffect(() => {
        requestConfigUpdate([AVAILABLE_CONFIGS.CANTEENS]);
    }, [requestConfigUpdate]);

    const [{queryLocation}, ] = useQueryFilters();

    const locationName = useMemo(() => {
        return config.canteens.filter((l) => l.value.toString() === queryLocation)[0].label;
    }, [config.canteens, queryLocation]);

    const [menu, setMenu] = useState([]);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        async function fetchMenu() {
            try {
                const response = await getCanteenRecipes(queryLocation, parseInt(new Date().getTime() / 1000), 'all', true);
                setMenu(response.data);
                setNotFound(false);
            } catch (error) {
                handleAxiosError(error, {
                    404: (e) => { setNotFound(true); }
                }, strings.dailyMenuPage__toastError__genericGetCanteenRecipesApiError);
            }
        }

        fetchMenu();
    }, [queryLocation]);

    const menuToShow = useMemo(() => {
        return filterMenu(menu, pfFilterCheck)
    }, [menu, pfFilterCheck]);

    return (
        <>
            <Header/>
            <div className={css.pageContainer}>
                <h1>{locationName}</h1>
                <div className={css.menuContainer}>
                    <div className={css.filterBar}>
                        <div className={css.checkboxContainer}>
                            <Checkbox
                                id='dailyPfFilterCheckbox'
                                checked={pfFilterCheck}
                                onChange={(val) => setPfFilterCheck(val)}
                            />
                            <span>{strings.dailyMenuPage__hidePFCheckboxLabel}</span>
                        </div>
                    </div>
                    { notFound ? 
                        <p>{strings.dailyMenuPage__menuNotFound}</p>
                        :
                        <div className={css.pageContent}>
                            {renderCategories(menuToShow)}
                        </div>
                    }
                </div>
            </div>
        </>
    );
}
