import Checkbox from "components/Checkbox/Checkbox";
import RoleSelector from "components/RoleSelector/RoleSelector";
import { ConfigContext } from "context";
import { AVAILABLE_CONFIGS } from "hooks/useConfig";
import { strings } from "localization";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { FEATURES } from "utils/constants";
import { isEmptyObj } from "utils/utils";
import { ACTIONS } from "views/UserManagementPage/UserManagementPage";

export default function CreateOrEditUserModal(props) {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    
    const [roleSelectorState, setRoleSelectorState] = useState({});

    const roleSelector = useRef(null);

    const config = useContext(ConfigContext);
    
    const requestConfigUpdate = config.requestConfigUpdate;
    useEffect(() => {
        requestConfigUpdate([AVAILABLE_CONFIGS.FEATURES]);
    }, [requestConfigUpdate]);
    
    const canCreateAdmin = config.features.has(FEATURES.CREATE_ADMIN);

    const onUserInitialized = props.onUserInitialized;

    useEffect(() => {
        if (props.initializationData === null) return;
        const data = props.initializationData;
        setUsername(data.username);
        setEmail(data.email);
        setName(data.name);
        setSurname(data.surname);
        setIsAdmin(data.isAdmin);
        roleSelector.current?.initialize(data.roles);
        onUserInitialized();
    }, [props.initializationData, onUserInitialized, roleSelector]);

    const clear = useCallback(() => {
        setUsername('');
        setEmail('');
        setName('');
        setSurname('');
        setIsAdmin(false);
        roleSelector.current?.initialize({});
    }, []);

    const toggle = props.toggle;
    const clearAndToggle = useCallback(() => {
        clear();
        toggle();
    }, [toggle, clear]);

    useEffect(() => {
        if (!props.isOpen) {
            clear();
        }
    }, [props.isOpen, clear]);

    const invalidState = !username || !email || !name || !surname || (isEmptyObj(roleSelectorState) && !isAdmin);

    let firstRowFieldSizes = ['6', '6'];
    if (canCreateAdmin) {
        firstRowFieldSizes = ['5', '5', '2'];
    }
    
    return (
        <Modal isOpen={props.isOpen} backdrop={true} toggle={clearAndToggle}>
            <ModalHeader toggle={clearAndToggle}>
                {props.mode === ACTIONS.EDIT ? strings.createOrEditUserModal__title__edit : strings.createOrEditUserModal__title__create}
            </ModalHeader>
            <ModalBody>
                <Form>
                    <Row>
                        <Col md={firstRowFieldSizes[0]}>
                            <FormGroup>
                                <Label for='usernameField'>{strings.createOrEditUserModal__usernameFieldLabel}</Label>
                                <Input
                                    id='usernameField'
                                    type='text'
                                    value={username}
                                    onChange={(e) => {setUsername(e.target.value)}}
                                    placeholder={strings.createOrEditUserModal__usernameFieldPlaceholder}
                                    spellCheck="false"
                                />
                            </FormGroup>
                        </Col>
                        <Col md={firstRowFieldSizes[1]}>
                            <FormGroup>
                                <Label for='emailField'>{strings.createOrEditUserModal__emailFieldLabel}</Label>
                                <Input
                                    id='emailField'
                                    type='email'
                                    value={email}
                                    onChange={(e) => {setEmail(e.target.value)}}
                                    placeholder={strings.createOrEditUserModal__emailFieldPlaceholder}
                                    spellCheck="false"
                                />
                            </FormGroup>
                        </Col>
                        { canCreateAdmin ?
                        <Col md={firstRowFieldSizes[2]}>
                            <FormGroup>
                                <Label for='createAdminCheckbox'>{strings.createOrEditUserModal__adminCheckboxLabel}</Label>
                                <Checkbox
                                    id='createAdminCheckbox'
                                    disabled={false}
                                    checked={isAdmin}
                                    onChange={(val) => {setIsAdmin(val)}}
                                />
                            </FormGroup>
                        </Col> : '' }
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label for='nameField'>{strings.createOrEditUserModal__nameFieldLabel}</Label>
                                <Input
                                    id='nameField'
                                    type='text'
                                    value={name}
                                    onChange={(e) => {setName(e.target.value)}}
                                    placeholder={strings.createOrEditUserModal__nameFieldPlaceholder}
                                    spellCheck="false"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label for='surnameField'>{strings.createOrEditUserModal__surnameFieldLabel}</Label>
                                <Input
                                    id='surnameField'
                                    type='text'
                                    value={surname}
                                    onChange={(e) => {setSurname(e.target.value)}}
                                    placeholder={strings.createOrEditUserModal__surnameFieldPlaceholder}
                                    spellCheck="false"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    { isAdmin ? '' : <RoleSelector ref={roleSelector} onChange={setRoleSelectorState}/>}
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={invalidState} onClick={() => props.onConfirm({username, email, name, surname, isAdmin, roles: roleSelectorState})}>
                    {strings.createOrEditUserModal__confirmButtonLabel}
                </Button>
                <Button color="secondary" onClick={clearAndToggle}>
                    {strings.createOrEditUserModal__cancelButtonLabel}
                </Button>
            </ModalFooter>
        </Modal>
    );
}
