import Header from 'components/Headers/Header';
import { LinkWithQuery } from 'components/LinkWithQuery/LinkWithQuery';
import { ConfigContext } from 'context';
import { useCategories } from 'hooks/useCategories';
import { AVAILABLE_CONFIGS } from 'hooks/useConfig';
import { useQueryFilters } from 'hooks/useQueryFilters';
import { strings } from 'localization';
import { useContext, useEffect, useMemo, useState } from 'react'
import Select from 'react-select';
import { Col, Form, FormGroup, Input, ListGroupItem, Row } from 'reactstrap';
import { getEmbeddingsInfo } from 'services/api';
import { handleAxiosError } from 'utils/utils';
import './ManagementPage.css'


function applyFilters(embeddingInfo, category, searchText) {
    // TODO: proseguire implementazione paginazione
    const filtered = embeddingInfo
        .map((emb, i) => {
            let passesCategoryFilter = false;

            if (category === 'ALL') {
                passesCategoryFilter = true;
            }

            if (emb.recipe_category === category) {
                passesCategoryFilter = true;
            }

            let passesTextFilter = false;
            if (emb?.recipe_name.toLowerCase().includes(searchText.toLowerCase())) {
                passesTextFilter = true;
            }

            if (passesCategoryFilter && passesTextFilter) {
                return i;
            }

            return undefined;
        })
        .filter(x => x !== undefined);
    return filtered;
}

function buildSeasonOptions(location, seasons) {
    const availableSeasons = [...seasons[location]];
    const options = [];

    for (const season of availableSeasons) {
        options.push({
            'label': season.name,
            'value': season.id
        });
    }

    return options;
}

function renderEmbeddingsInfo(embeddingInfo, filteredEmbInfoIndices, searchText, location) {
    return filteredEmbInfoIndices.map((embIdx, idx) => {
        const emb = embeddingInfo[embIdx];
        const state = {
            searchText,
            embInfo: embeddingInfo,
            embInfoIndices: filteredEmbInfoIndices,
            currEmbIndex: idx
        };
        return (
            <ListGroupItem key={emb['recipe_id']}>
                <LinkWithQuery to={{pathname: "/admin/management/" + location + "/" + emb['recipe_id']}} state={state} isNav={false}>
                    <div className='statistics-listGroupItemContent'>
                        <span>{emb['recipe_name'] + '   '}</span>
                        <span>{emb.number_of_embeddings}</span>
                    </div>
               </LinkWithQuery>
            </ListGroupItem>
        )
    });
}

export default function ManagementPage(props) {
    const config = useContext(ConfigContext);

    const requestConfigUpdate = config.requestConfigUpdate;
    useEffect(() => {
        requestConfigUpdate([AVAILABLE_CONFIGS.CANTEENS, AVAILABLE_CONFIGS.SEASONS]);
    }, [requestConfigUpdate]);

    const locations = config.canteens;
    const seasons = config.seasons;
    const categories = useCategories();

    const [{queryLocation, queryCategory}, ] = useQueryFilters();


    const [searchText, setSearchText] = useState(props.location.state?.initialSearchText ? props.location.state?.initialSearchText : '');
    const [embeddingInfo, setEmbeddingInfo] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState(null);
    const [seasonOptions, setSeasonOptions] = useState([]);

    const location = useMemo(() => {
        return locations.filter((l) => l.value.toString() === queryLocation)[0];
    }, [locations, queryLocation]);

    const category = useMemo(() => {
        return categories.filter((c) => c.value.toString() === queryCategory)[0];
    }, [categories, queryCategory]);

    useEffect(() => {
        async function fetchData() {        
            let newEmbeddingInfo = [];

            try {
                // TODO: filtrare per season
                newEmbeddingInfo = (await getEmbeddingsInfo(queryLocation, selectedSeason?.value)).data;
            } catch (error) {
                handleAxiosError(error, {}, strings.managementPage__genericApiError)
            }
            
            setEmbeddingInfo(newEmbeddingInfo);
        }
        fetchData();
    }, [queryLocation, selectedSeason]);

    useEffect(() => {
        setSeasonOptions(buildSeasonOptions(location.value, seasons));
    }, [location.value, seasons]);

    const filteredEmbInfoIndices = useMemo(() => {
        return applyFilters(embeddingInfo, category.value, searchText);
    }, [embeddingInfo, category, searchText]);

    const title = location.label + ' - ' + category.label

    return (
        <>
            <Header/>
            <div className='managementPageContainer'>
                <div className='managementPageContent'>
                    <h1 className='managementPageTitle'>{title}</h1>
                    <div className='managementFilterFormContainer'>
                        <Form>
                            <Row>
                                <Col md="8">
                                    <FormGroup>
                                        <Input
                                            type='text'
                                            value={searchText}
                                            className='managementPageSearchField'
                                            onChange={(e) => setSearchText(e.target.value)}
                                            placeholder={strings.managementPage__searchFieldPlaceholder}
                                            spellCheck="false"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Select
                                            value={selectedSeason}
                                            onChange={(s) => setSelectedSeason(s)}
                                            isClearable={true}
                                            options={seasonOptions}
                                            placeholder={strings.managementPage__seasonFieldPlaceholder}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    {
                    filteredEmbInfoIndices.length > 0 ? (
                        <div>
                            <div className='managementRecipeListHeader'>
                                <h2>{strings.managementPage__dishColumnName}</h2>
                                <h2>{strings.managementPage__exampleCountColumnName}</h2>
                            </div>
                            <div className='managementRecipeListContainer'>
                                <ul className='list-group'>
                                    {renderEmbeddingsInfo(embeddingInfo, filteredEmbInfoIndices, searchText, location.value)}
                                </ul>
                            </div>
                        </div>
                    ) : (
                        <h2>{strings.managementPage__noResults}</h2>
                    )
                    }
                </div>
            </div>
        </>
    );
}
