import { Link, useLocation } from 'react-router-dom';
import { NavLink } from 'reactstrap';

export const LinkWithQuery = ({ children, to, isNav, state, copyState, ...props }) => {
  const { search, state: oldState } = useLocation();

  let newPathname = to;
  let newState = {};

  if (typeof to !== 'string') {
    newPathname = to.pathname;
    if (copyState) {
      newState = oldState;
    }
  }

  if (state) {
    newState = {...newState, ...state}
  }

  const newTo = {
    pathname: newPathname,
    search: search,
    state: newState
  }

  return (
    <>
      { isNav ?
        <NavLink to={newTo} {...props}>
          {children}
        </NavLink>
      :
        <Link to={newTo} {...props}>
            {children}
        </Link>
      }
    </>
  );
};
