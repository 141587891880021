import css from "./SearchFilterModal.module.css"
import { strings } from 'localization';
import { Modal, ModalHeader, ModalFooter, ModalBody, Button, Col, FormGroup, Label, Row, Form } from 'reactstrap';
import ReactDatePicker from "react-datepicker";
import Select from 'react-select';
import Checkbox from "components/Checkbox/Checkbox";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ConfigContext } from "context";
import { getSelectableDates } from "services/api";
import { handleAxiosError } from "utils/utils";
import { ALL_RECIPES } from "utils/constants";
import { SEARCH_MODAL_FILTERS } from "utils/constants";
import { useAllowedFilters } from "hooks/useAllowedFilters";
import { useQueryFilters } from "hooks/useQueryFilters";
import { useCategories } from "hooks/useCategories";
import { AVAILABLE_CONFIGS } from "hooks/useConfig";
import CustomDateInput from "components/CustomDateInput/CustomDateInput";
import { TIME_INTERVALS, DATE_FORMATS, TIME_FORMATS } from "utils/constants";

const TIME_PERIOD_OPTION_DONT_FILTER_INDEX = 0;
const TIME_PERIOD_OPTION_CUSTOM_FILTER = 1;
const CALENDAR_START_DAY_OF_WEEK = 1;

const customDayContents = (selectableDates) => {
  
  return (day, date) => {
    const styles = {};
    let found = false;

    for (const selectableDate of selectableDates) {
      if (
        selectableDate.getDate() === date.getDate() &&
        selectableDate.getMonth() === date.getMonth() &&
        selectableDate.getFullYear() === date.getFullYear()
      ) {
        found = true;
        break;
      }
    }

    if (found) {
      styles['color'] = 'red';
    }

    return <span style={styles}>{day}</span>;
  }
};

function roundTime(date) {
    date = new Date(date);
    let minutes = date.getMinutes();
    minutes = minutes % TIME_INTERVALS === 0 ? minutes : TIME_INTERVALS * Math.round(minutes / TIME_INTERVALS);
    date.setMinutes(minutes);
    return date;
}

function filterRecipes(recipes, location, category) {
    const newRecipes = [ALL_RECIPES];
    for (const recipe of recipes) {
        if (recipe.canteen.toString() !== location['value']) continue;
        if (recipe.category_id !== category['value'] && category['value'] !== 'ALL') continue;
        newRecipes.push({
            value: recipe.recipe_id,
            label: recipe.recipe_name
        });
      }
    return newRecipes;
}

function generateTimePeriodsOptions(selectedLocation, seasons, onDontFilter, onTimePeriodSelected, onCustomTimePeriodSelected) {
    const availableSeasons = [...seasons[selectedLocation]];
    const options = []

    const dontFilter = {
        label: strings.searchFilterModal__timePeriodField__dontFilterLabel,
        value: {
            start: null,
            end: null,
            callback: onDontFilter
        }
    }

    const customFilter = {
        label: strings.searchFilterModal__timePeriodField__customFilterLabel,
        value: {
            start: null,
            end: null,
            callback: onCustomTimePeriodSelected
        }
    }

    const today = new Date();
    const todayStr = today.toISOString().split('T')[0]

    let firstOfWeek = today.getDate() - today.getDay() + CALENDAR_START_DAY_OF_WEEK;
    let lastOfWeek = firstOfWeek + 6 + CALENDAR_START_DAY_OF_WEEK;
    firstOfWeek = new Date(new Date(today).setDate(firstOfWeek)).toISOString().split('T')[0];
    lastOfWeek = new Date(new Date(today).setDate(lastOfWeek)).toISOString().split('T')[0];

    availableSeasons.unshift({
        id: -1,
        name: strings.searchFilterModal__timePeriodField__WeekFilterLabel,
        start_date: firstOfWeek,
        end_date: lastOfWeek
    });

    availableSeasons.unshift({
        id: -2,
        name: strings.searchFilterModal__timePeriodField__TodayFilterLabel,
        start_date: todayStr,
        end_date: todayStr
    });

    options.push(dontFilter);
    options.push(customFilter);

    for (const season of availableSeasons) {
        const startDateAndTime = new Date(new Date(season.start_date).setHours(0, 0, 0, 0));
        const endDateAndTime = new Date(new Date(season.end_date).setHours(23, 45, 0, 0));

        options.push({
            'label': season.name,
            'value': {
                start: startDateAndTime,
                end: endDateAndTime,
                callback: () => onTimePeriodSelected(startDateAndTime, endDateAndTime)
            }
        })
    }

    return options;
}

function matchTimePeriod(timePeriods, startDateTime, endDateTime, dateFilterEnabled) {
    if (!dateFilterEnabled) {
        return timePeriods[TIME_PERIOD_OPTION_DONT_FILTER_INDEX];
    }

    for (const timePeriod of timePeriods) {
        if (timePeriod.value.start?.getTime() === startDateTime.getTime()) {
            if (timePeriod.value.end?.getTime() === endDateTime.getTime()) {
                return timePeriod;
            }
        }
    }

    return timePeriods[TIME_PERIOD_OPTION_CUSTOM_FILTER];
}

export default function SearchFilterModal(props) {
    const config = useContext(ConfigContext);

    const requestConfigUpdate = config.requestConfigUpdate;
    useEffect(() => {
        requestConfigUpdate([AVAILABLE_CONFIGS.CANTEENS, AVAILABLE_CONFIGS.SEASONS, AVAILABLE_CONFIGS.RECIPES]);
    }, [requestConfigUpdate]);

    const locations = config.canteens;
    const seasons = config.seasons;
    const categories = useCategories();
    const recipes = config.recipes;

    const allowedFilters = useAllowedFilters();

    const [
        { queryLocation, queryCategory, queryRecipe, queryFrom, queryTo, queryErrorFilter, queryEnableDateFilter },
        { setQueryLocation, setQueryCategory, setQueryRecipe, setQueryFrom, setQueryTo, setQueryErrorFilter, setQueryEnableDateFilter }
    ] = useQueryFilters();

    // LOCAL FILTERS
    const [locationLocal, setLocationLocal] = useState(locations.filter((l) => l.value === queryLocation)[0]);
    const categoriesLocal = useCategories(locationLocal.value);
    const [categoryLocal, setCategoryLocal] = useState(categoriesLocal.filter((c) => c.value === queryCategory)[0]);
    const [recipeLocal, setRecipeLocal] = useState(filterRecipes(recipes, locationLocal, categoryLocal).filter((r) => r.value === queryRecipe)[0]);
    const [enableDateFilterLocal, setEnableDateFilterLocal] = useState(queryEnableDateFilter);
    const [startDateTimeLocal, setStartDateTimeLocal] = useState(new Date(queryFrom * 1000));
    const [endDateTimeLocal, setEndDateTimeLocal] = useState(new Date(queryTo * 1000));

    const onDontFilter = useCallback(() => {
        setEnableDateFilterLocal(false);
    }, []);

    const onTimePeriodSelected = useCallback((startDateAndTime, endDateAndTime) => {
        setStartDateTimeLocal(startDateAndTime);
        setEndDateTimeLocal(endDateAndTime);
        setEnableDateFilterLocal(true);
    }, []);

    const onCustomTimePeriodSelected = useCallback(() => {
        setEnableDateFilterLocal(true);
    }, []);

    const timePeriodOptions = useMemo(() => {
        return generateTimePeriodsOptions(locationLocal.value, seasons, onDontFilter, onTimePeriodSelected, onCustomTimePeriodSelected);
    }, [locationLocal.value, onCustomTimePeriodSelected, onDontFilter, onTimePeriodSelected, seasons]);

    const [selectedTimePeriod, setSelectedTimePeriod] = useState(matchTimePeriod(timePeriodOptions, startDateTimeLocal, endDateTimeLocal, enableDateFilterLocal));
    const [errorFilterLocal, setErrorFilterLocal] = useState(queryErrorFilter);

    const [selectableDates, setSelectableDates] = useState([]);
    const [startTimeErrors, setStartTimeErrors] = useState([]);
    const [endTimeErrors, setEndTimeErrors] = useState([]);
    const [startDateErrors, setStartDateErrors] = useState([]);
    const [endDateErrors, setEndDateErrors] = useState([]);

    useEffect(() => {
        setSelectedTimePeriod(matchTimePeriod(timePeriodOptions, startDateTimeLocal, endDateTimeLocal, enableDateFilterLocal));
    }, [timePeriodOptions, startDateTimeLocal, endDateTimeLocal, enableDateFilterLocal]);

    useEffect(() => {
        const newStartTimeErrors = [];
        const newEndTimeErrors = [];
        const newStartDateErrors = [];
        const newEndDateErrors = [];

        const startDate = new Date(startDateTimeLocal);
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(endDateTimeLocal);
        endDate.setHours(0, 0, 0, 0);

        if (startDate.getTime() === endDate.getTime() && startDateTimeLocal > endDateTimeLocal) {
            newStartTimeErrors.push(strings.searchFilterModal__formError__startTimeBeforeEndTime);
            newEndTimeErrors.push(strings.searchFilterModal__formError__endTimeAfterStartTime);
        }

        if (startDate > endDate) {
            newStartDateErrors.push(strings.searchFilterModal__formError__startDateBeforeEndDate);
            newEndDateErrors.push(strings.searchFilterModal__formError__endDateAfterStartDate);
        }

        if (startDateTimeLocal > new Date()) {
            newStartTimeErrors.push(strings.searchFilterModal__formError__startTimeBeforeCurrentTime);
        }

        setStartTimeErrors(newStartTimeErrors);
        setEndTimeErrors(newEndTimeErrors);
        setStartDateErrors(newStartDateErrors);
        setEndDateErrors(newEndDateErrors);
    }, [startDateTimeLocal, endDateTimeLocal]);

    useEffect(() => {
        async function fetchSelectableDates() {
            let newSelectableDates = [];
            try {
                newSelectableDates = (await getSelectableDates(locationLocal.value)).data.map(d => new Date(d));
            } catch (error) {
                handleAxiosError(error, {}, strings.searchFilterModal__toastError__genericSelectableDatesApiError);
            }
            setSelectableDates(newSelectableDates);
        }

        fetchSelectableDates();
    }, [locationLocal]);

    useEffect(() => {
        const newCategoryLocal = categoriesLocal.includes(categoryLocal) ? categoryLocal : categoriesLocal[0];
        const newRecipeCandidate = filterRecipes(recipes, locationLocal, newCategoryLocal).filter((r) => r.value === recipeLocal.value);
        if (newRecipeCandidate.length !== 1) setRecipeLocal(ALL_RECIPES);
        if (newCategoryLocal !== categoryLocal) setCategoryLocal(newCategoryLocal);
    }, [locationLocal, categoriesLocal, recipes, categoryLocal, recipeLocal]);

    const toggleModal = props.toggle;

    const resetLocal = useCallback(() => {
        const oldLocation = locations.filter((l) => l.value === queryLocation)[0];
        const oldCategory = categories.filter((c) => c.value === queryCategory)[0];
        setLocationLocal(oldLocation);
        setCategoryLocal(oldCategory);
        setRecipeLocal(filterRecipes(recipes, oldLocation, oldCategory).filter((r) => r.value === queryRecipe)[0]);
        setEnableDateFilterLocal(queryEnableDateFilter);
        setStartDateTimeLocal(new Date(queryFrom * 1000));
        setEndDateTimeLocal(new Date(queryTo * 1000));
        setErrorFilterLocal(queryErrorFilter);
    }, [locations, categories, recipes, queryLocation, queryCategory, queryRecipe, queryEnableDateFilter, queryFrom, queryTo, queryErrorFilter]);

    const cancelOp = useCallback(() => {
        resetLocal();
        toggleModal();
    }, [resetLocal, toggleModal]);

    const applyChanges = useCallback(() => {
        setQueryLocation(locationLocal.value);
        setQueryCategory(categoryLocal.value);
        setQueryRecipe(recipeLocal.value);
        setQueryEnableDateFilter(enableDateFilterLocal);
        
        const startDateTime = new Date(startDateTimeLocal);
        const endDateTime = new Date(endDateTimeLocal);
        startDateTime.setHours(startDateTime.getHours(), startDateTime.getMinutes(), 0, 0);
        endDateTime.setHours(endDateTime.getHours(), endDateTime.getMinutes(), 0, 0);
        setQueryFrom(startDateTime.getTime() / 1000);
        setQueryTo(endDateTime.getTime() / 1000);

        setQueryErrorFilter(errorFilterLocal);
        toggleModal();
    }, [
        setQueryLocation, setQueryCategory, setQueryRecipe, setQueryEnableDateFilter, setQueryFrom,
        setQueryTo, setQueryErrorFilter, toggleModal, locationLocal,
        categoryLocal, recipeLocal, enableDateFilterLocal, startDateTimeLocal, endDateTimeLocal, errorFilterLocal
    ]);

    const handleTimePeriodSelected = useCallback((timePeriod) => {
        setSelectedTimePeriod(timePeriod);
        timePeriod.value.callback();
    }, []);

    useEffect(() => {
        resetLocal();
    }, [queryLocation, queryCategory, queryRecipe, queryFrom, queryTo, queryErrorFilter, queryEnableDateFilter, resetLocal]);


    const formReady = startTimeErrors.length === 0 && endTimeErrors.length === 0 && startDateErrors.length === 0 && endDateErrors.length === 0;

    return (
        <Modal className={css.modalXl} isOpen={props.isOpen} backdrop={true} toggle={() => cancelOp()}>
            <ModalHeader toggle={() => cancelOp()}>
                {strings.searchFilterModal__title}
            </ModalHeader>
            <ModalBody>
              <Form>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label for='locationSelect'>{strings.searchFilterModal__locationFieldLabel}</Label>
                      <Select
                        id='locationSelect'
                        isDisabled={!allowedFilters.has(SEARCH_MODAL_FILTERS.LOCATION_FILTER)}
                        value={locationLocal}
                        onChange={(l) => setLocationLocal(l)}
                        options={locations}
                        placeholder={strings.searchFilterModal__locationFieldPlaceholder}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for='categorySelect'>{strings.searchFilterModal__categoryFieldLabel}</Label>
                      <Select
                        id='categorySelect'
                        isDisabled={!allowedFilters.has(SEARCH_MODAL_FILTERS.CATEGORY_FILTER)}
                        value={categoryLocal}
                        onChange={(c) => setCategoryLocal(c)}
                        options={categoriesLocal}
                        placeholder={strings.searchFilterModal__categoryFieldPlaceholder}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for='recipeSelect'>{strings.searchFilterModal__recipeFieldLabel}</Label>
                      <Select
                        id='recipeSelect'
                        isDisabled={!allowedFilters.has(SEARCH_MODAL_FILTERS.RECIPE_FILTER)}
                        value={recipeLocal}
                        onChange={(r) => setRecipeLocal(r)}
                        options={filterRecipes(recipes, locationLocal, categoryLocal)}
                        placeholder={strings.searchFilterModal__recipeFieldPlaceholder}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr/>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label for='timePeriodSelect'>{strings.searchFilterModal__timePeriodFieldLabel}</Label>
                      <Select
                        id='timePeriodSelect'
                        isDisabled={!allowedFilters.has(SEARCH_MODAL_FILTERS.DATE_TIME_FILTER)}
                        value={selectedTimePeriod}
                        onChange={(tp) => handleTimePeriodSelected(tp)}
                        options={timePeriodOptions}
                        placeholder={strings.searchFilterModal__timePeriodFieldPlaceholder}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for='startDatePicker'>{strings.searchFilterModal__startDateFieldLabel}</Label>
                      <ReactDatePicker
                        id='startDatePicker'
                        disabled={!enableDateFilterLocal || !allowedFilters.has(SEARCH_MODAL_FILTERS.DATE_TIME_FILTER)}
                        dateFormat={DATE_FORMATS[strings.locale]}
                        calendarStartDay={CALENDAR_START_DAY_OF_WEEK}
                        locale={strings.locale}
                        selected={startDateTimeLocal}
                        onChange={(date) => setStartDateTimeLocal(date)}
                        renderDayContents={customDayContents(selectableDates)}
                        filterDate={(date) => date < new Date()}
                        customInput={<CustomDateInput />}
                      />
                    </FormGroup>
                    {startDateErrors.length > 0 ? (<p className={css.errorMessage}>{startDateErrors[0]}</p>) : ''}
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for='startTimePicker'>{strings.searchFilterModal__startTimeFieldLabel}</Label>
                      <ReactDatePicker
                        id='startTimePicker'
                        disabled={!enableDateFilterLocal || !allowedFilters.has(SEARCH_MODAL_FILTERS.DATE_TIME_FILTER)}
                        className={css.customDateInput}
                        selected={startDateTimeLocal}
                        onChange={(d) => setStartDateTimeLocal(roundTime(d))}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={TIME_INTERVALS}
                        timeCaption="Orario"
                        dateFormat={TIME_FORMATS[strings.locale]}
                        locale={strings.locale}
                      />
                      {startTimeErrors.length > 0 ? (<p className={css.errorMessage}>{startTimeErrors[0]}</p>) : ''}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4"></Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for='endDatePicker'>{strings.searchFilterModal__endDateFieldLabel}</Label>
                      <ReactDatePicker
                        id='endDatePicker'
                        disabled={!enableDateFilterLocal || !allowedFilters.has(SEARCH_MODAL_FILTERS.DATE_TIME_FILTER)}
                        dateFormat={DATE_FORMATS[strings.locale]}
                        calendarStartDay={CALENDAR_START_DAY_OF_WEEK}
                        locale={strings.locale}
                        selected={endDateTimeLocal}
                        onChange={(date) => setEndDateTimeLocal(date)}
                        renderDayContents={customDayContents(selectableDates)}
                        filterDate={(date) => date < new Date()}
                        customInput={<CustomDateInput />}
                      />
                    </FormGroup>
                    {endDateErrors.length > 0 ? (<p className={css.errorMessage}>{endDateErrors[0]}</p>) : ''}
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for='endTimePicker'>{strings.searchFilterModal__endTimeFieldLabel}</Label>
                      <ReactDatePicker
                        id='endTimePicker'
                        disabled={!enableDateFilterLocal || !allowedFilters.has(SEARCH_MODAL_FILTERS.DATE_TIME_FILTER)}
                        className={css.customDateInput}
                        selected={endDateTimeLocal}
                        onChange={(d) => setEndDateTimeLocal(roundTime(d))}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={TIME_INTERVALS}
                        timeCaption="Orario"
                        dateFormat={TIME_FORMATS[strings.locale]}
                        locale={strings.locale}
                      />
                      {endTimeErrors.length > 0 ? (<p className={css.errorMessage}>{endTimeErrors[0]}</p>) : ''}
                    </FormGroup>
                  </Col>
                </Row>
                <hr/>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label for='errorFilterCheckbox'>{strings.searchFilterModal__errorFilterFieldLabel}</Label>
                      <Checkbox
                        id='errorFilterCheckbox'
                        disabled={!allowedFilters.has(SEARCH_MODAL_FILTERS.ERROR_FILTER)}
                        checked={errorFilterLocal}
                        onChange={(val) => setErrorFilterLocal(val)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => applyChanges()} disabled={!formReady}>
                    {strings.searchFilterModal__confirmButtonLabel}
                </Button>
                <Button color="secondary" onClick={() => cancelOp()}>
                    {strings.searchFilterModal__cancelButtonLabel}
                </Button>
            </ModalFooter>
        </Modal>
    );
}