import Header from 'components/Headers/Header';
import { strings } from 'localization';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Col, Form, FormGroup, Input, Row } from 'reactstrap';
import css from './SystemLogsPage.module.css'
import SystemLogsTable from 'components/SystemLogsTable/SystemLogsTable';
import CustomDateInput from 'components/CustomDateInput/CustomDateInput';
import ReactDatePicker from 'react-datepicker';
import { DATE_FORMATS } from 'utils/constants';
import Select from 'react-select';
import { ConfigContext } from 'context';
import { AVAILABLE_CONFIGS } from 'hooks/useConfig';
import { handleAxiosError } from 'utils/utils';
import { getUsers, getSystemLogs } from 'services/api';

function userObjToDropdownValueLabelObj(user) {
    return {
        "value": user.id,
        "label": user.username
    };
}

export default function SystemLogsPage() {
    const config = useContext(ConfigContext);

    const requestConfigUpdate = config.requestConfigUpdate;
    useEffect(() => {
        requestConfigUpdate([AVAILABLE_CONFIGS.SYSTEM_LOGS_CATEGORIES]);
    }, [requestConfigUpdate]);


    const [searchText, setSearchText] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedType, setSelectedType] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectableUsers, setSelectableUsers] = useState([]);
    const [logs, setLogs] = useState([]);

    
    useEffect(() => {
        async function fetchUsers() {
            try {
                const response = await getUsers();
                setSelectableUsers(response.data.map((u) => userObjToDropdownValueLabelObj(u)));
            } catch (error) {
                handleAxiosError(error, {}, strings.systemLogsPage__toastError__genericGetUsersApiError);
            }
        }
        fetchUsers();
    }, []);
    
    useEffect(() => {
        async function fetchLogs() {
            try {
                const response = await getSystemLogs(parseInt(selectedDate.getTime() / 1000), selectedType?.value, selectedUser?.label);
                setLogs(response.data)
            } catch (error) {
                handleAxiosError(error, {}, strings.systemLogsPage__toastError__genericGetSystemLogsApiError);
            }
        }
        fetchLogs();
    }, [selectedDate, selectedType, selectedUser]);

    const filteredLogs = useMemo(() => {
        return logs.filter((l) => l.message.toLowerCase().includes(searchText.toLowerCase()));
    }, [logs, searchText]);

    return (
        <>
            <Header />
            <div className={css.container}>
                <Form className='mt-3'>
                    <Row>
                        <Col md="3">
                            <FormGroup>
                                <ReactDatePicker
                                    dateFormat={DATE_FORMATS[strings.locale]}
                                    calendarStartDay={1}
                                    locale={strings.locale}
                                    selected={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                    customInput={<CustomDateInput />}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Select
                                    value={selectedType}
                                    onChange={(t) => setSelectedType(t)}
                                    options={config.system_logs_categories}
                                    isClearable={true}
                                    placeholder={strings.systemLogsPage__typeFieldPlaceholder}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Select
                                    value={selectedUser}
                                    onChange={(u) => setSelectedUser(u)}
                                    options={selectableUsers}
                                    isClearable={true}
                                    placeholder={strings.systemLogsPage__userFieldPlaceholder}
                                />  
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Input
                                    id='syslogSearchBar'
                                    type='text'
                                    value={searchText}
                                    className={css.searchField}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    placeholder={strings.systemLogsPage__searchFieldPlaceholder}
                                    spellCheck="false"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                

                <SystemLogsTable
                    logs={filteredLogs}
                />
            </div>
        </>
    );
}
