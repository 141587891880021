import Header from 'components/Headers/Header';
import ReassignRecipeModal from 'components/ReassignRecipeModal/ReassignRecipeModal';
import WKTVisualizer from 'components/WKTVisualizer/WKTVisualizer';
import { ConfigContext } from 'context';
import { AVAILABLE_CONFIGS } from 'hooks/useConfig';
import { usePrevious } from 'hooks/usePrevious';
import { strings } from 'localization';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { addExistingEmbedding } from 'services/api';
import { getImage } from 'services/api';
import { getDetectionDetails } from 'services/api';
import { DISH_ID } from 'utils/constants';
import { FEATURES } from 'utils/constants';
import { formatLongDateAndTime } from 'utils/utils';
import { toastSuccess } from 'utils/utils';
import { toastWarn } from 'utils/utils';
import { DefaultDict } from 'utils/utils';
import { handleAxiosError } from 'utils/utils';
import './DetailsPage.css';


function aggregateRecipes(detections) {
    const countDict = new DefaultDict(0);
    const recipeNames = {};
    const recipes = []

    for (const detection of detections) {
        const recipeId = detection['recipe_id'];
        
        if (recipeId === DISH_ID) continue;

        countDict[recipeId] += 1
        recipeNames[recipeId] = detection['recipe_name'];
    }
    
    for (const [id, name] of Object.entries(recipeNames)) {
        recipes.push({
            'id': id,
            'name': name,
            'count': countDict[id]
        });
    }

    return recipes;
}

function renderRecipeList(recipes) {
    return recipes.map((recipe) => (
        <ListGroupItem key={recipe.id}>
            <div className='listGroupItemContent'>
                <span>{recipe.name + '   '}</span>
                <span>{recipe.count}</span>
            </div>
        </ListGroupItem>
    ));
}

function filterDetections(detections, removeDishes) {
    if (!removeDishes) return detections;
    return detections.filter((d) => d['recipe_id'] !== DISH_ID)
}

export default function DetailsPage(props) {
    const config = useContext(ConfigContext);
    const history = useHistory();

    const requestConfigUpdate = config.requestConfigUpdate;
    useEffect(() => {
        requestConfigUpdate([AVAILABLE_CONFIGS.FEATURES, AVAILABLE_CONFIGS.SPECIFIC_TO_GENERIC_MAP]);
    }, [requestConfigUpdate]);

    const [details, setDetails] = useState({
        site_id: '',
        image_url: '',
        prev: '',
        next: '',
        index: 0,
        count: 1,
        timestamp: -1,
        detections: [],
        edit_detections: []
    });

    const [blobUrl, setBlobUrl] = useState('');
    const [showDishes, setShowDishes] = useState(true);
    const [selectedDetection, setSelectedDetection] = useState(null);
    
    const canAddEmbeddings = config.features.has(FEATURES.ADD_EXISTING_EMBEDDINGS);
    const specific_to_generic_map = config.specific_to_generic_map;

    const map_specific_recipe_to_generic = useCallback((specific_id) => {
        if (details.site_id === '') return specific_id;
        const generic_id = specific_to_generic_map[details.site_id][specific_id];
        return generic_id ? generic_id : specific_id;
    }, [specific_to_generic_map, details.site_id]);

    const loadImageBlob = useCallback(async (url) => {
        const response = await getImage(url);
        return URL.createObjectURL(response.data);
    }, []);

    const loadDetails = useCallback(async (id) => {
        try {
            let detailsPromise = null;

            if (props.location.state) {
                const from = props.location.state.from;
                const to = props.location.state.to;
                const location = props.location.state.location;
                const category = props.location.state.category;
                const recipe = props.location.state.recipe;
                const errorFilter = props.location.state.errorFilter;

                const actualFrom = props.location.state.enableDateFilter ? from : 1;
                const actualTo = props.location.state.enableDateFilter ? to : 2147483647; // TODO: solve Y2038 bug

                detailsPromise = getDetectionDetails(id, actualFrom, actualTo, location, category, recipe, errorFilter);
            } else {
                detailsPromise = getDetectionDetails(id);
            }

            const newDetails = (await detailsPromise).data;
            const newBlobUrl = newDetails['image_url'] ? await loadImageBlob(newDetails['image_url']) : '';

            setDetails(newDetails);
            setBlobUrl(newBlobUrl);
        } catch (error) {
            handleAxiosError(error, {}, strings.detailsPage__toastError__genericApiError);
        }
    }, [props.location, loadImageBlob]);

    const handleArrowClick = useCallback(async () => {
        URL.revokeObjectURL(blobUrl);
    }, [blobUrl]);

    const handleKeyDown = useCallback(async (e) => {
        if (e.repeat) return;
        switch (e.which) {
            case 37:
                // left arrow pressed
                if (details.prev) {
                    handleArrowClick();
                    history.push({pathname: '/admin/details/' + details.prev, state: props.location.state});
                }
                break;
            case 39:
                // right arrow pressed
                if (details.next) {
                    handleArrowClick();
                    history.push({pathname: '/admin/details/' + details.next, state: props.location.state});
                }
                break;
            default:
                break;
        }
    }, [details, handleArrowClick, history, props.location.state]);

    useEffect(() => {
        loadDetails(props.match.params.id);
    }, [loadDetails, props.match.params.id]);

    const prevHandleKeyDown = usePrevious(handleKeyDown);

    useEffect(() => {
        document.removeEventListener("keydown", prevHandleKeyDown, false);
        document.addEventListener("keydown", handleKeyDown, false);

        return () => {
            document.removeEventListener("keydown", handleKeyDown, false);
        };
    }, [prevHandleKeyDown, handleKeyDown]);

    useEffect(() => {
        return () => {
            URL.revokeObjectURL(blobUrl);
        };
    }, [blobUrl]);

    const aggregatedDetectedRecipes = aggregateRecipes(details.detections);

    const aggregatedEditedRecipes = aggregateRecipes(details['edit_detections']);

    const detectedRecipes = renderRecipeList(aggregatedDetectedRecipes);

    const editedRecipes = renderRecipeList(aggregatedEditedRecipes);

    const dateTime = formatLongDateAndTime(details.timestamp);
    const dateTimeStr = details.timestamp !== -1 ? strings.formatString(strings.detailsPage__pageTitle, {
        date: dateTime['day'], 
        time: dateTime['time']
    }) : strings.detailsPage__loadingTitle;

    const detectedRecipeListMessage = 
        details.detections.length > 0 ?
        strings.detailsPage__nutrayRecipesLabel :
        strings.detailsPage__noNutrayRecipesLabel;
    
    const operatorRecipesLabel = details.operator ?
        strings.formatString(strings.detailsPage__operatorRecipesLabel__withOperatorName, {operator: details.operator})
        : strings.detailsPage__operatorRecipesLabel;

    const editedRecipeListMessage = 
        details['edit_detections'].length > 0 ?
        operatorRecipesLabel :
        strings.detailsPage__noOperatorRecipesLabel;

    const galleryLink = {pathname: '/admin/gallery'};

    if (props.location.state) {
        const page = Math.floor(details.index / props.location.state.perPage) + 1;
        const urlPar = new URLSearchParams();
        urlPar.set("from", props.location.state.from);
        urlPar.set("to", props.location.state.to);
        urlPar.set("location", props.location.state.location);
        urlPar.set("category", props.location.state.category);
        urlPar.set("page", page);
        urlPar.set("recipe", props.location.state.recipe);
        urlPar.set("errorFilter", props.location.state.errorFilter);
        urlPar.set("enableDateFilter", props.location.state.enableDateFilter);
        galleryLink['search'] = urlPar.toString();
    }

    const nutrayDetectionsContainerClass = details.edit_detections.length <= 0 ? 'nutrayDetectionsContainer_small' : 'nutrayDetectionsContainer';

    const detections = useMemo(() => filterDetections(details.detections, !showDishes), [details.detections, showDishes]);
    const edit_detections = useMemo(() => filterDetections(details.edit_detections, !showDishes), [details.edit_detections, showDishes]);

    const handleDetectionClick = useCallback((detection) => {
        setSelectedDetection(detection);
    }, [])

    const handleModalAddEmbedding = useCallback(async (recipe_id) => {
        if (!selectedDetection) { return }
        let emb_id = details.detection_embeddings_id;

        if (selectedDetection.new_embedding_id) {
            emb_id = selectedDetection.new_embedding_id;
        }

        try {
            const response = await addExistingEmbedding(details.site_id, emb_id, selectedDetection.crop_id, recipe_id)
            const msg_code = response.data.msg_code;
            if (msg_code === 'detailsPage__toastSuccess__embeddingNotModified') {
                toastWarn(strings[msg_code])
            } else {
                toastSuccess(strings[msg_code]);
            }
            setSelectedDetection(null);
        } catch (error) {
            handleAxiosError(error, {}, strings.detailsPage__toastError__genericAddEmbeddingApiError)
        }
    }, [details.detection_embeddings_id, details.site_id, selectedDetection])

    return (
        <>
        <Header />
        <div className='detailsPageContainer'>
            <h1 className='detailsTimeStamp'>{dateTimeStr}</h1>
            <div className='detailsContent'>
                <div className='detailsButtonBarContainer'>
                    <div className='detailsHalfButtonBarLeft'>
                        <Link
                            className='btn btn-primary detailsLeftButtons'
                            to={galleryLink}
                        >
                            {strings.detailsPage__backButtonLabel}
                        </Link>
                        <Link
                            className='btn btn-primary detailsLeftButtons'
                            to="#"
                            onClick={(e) => {e.preventDefault(); setShowDishes(!showDishes)}}
                        >
                            {strings['detailsPage__showDishesButtonLabel__state_' + showDishes.toString()]}
                        </Link>
                    </div>
                    <div className='detailsHalfButtonBarRight'>
                        {details.prev ?
                        <Link
                            className='btn btn-primary detailsRightButtons'
                            onClick={(e) => handleArrowClick()}
                            to={{pathname: '/admin/details/' + details.prev, state: props.location.state}}
                        >
                            <i className='ni ni-bold-left'></i>
                        </Link> : '' }
                        {details.next ?
                        <Link
                            className='btn btn-primary detailsRightButtons'
                            onClick={(e) => handleArrowClick()}
                            to={{pathname: '/admin/details/' + details.next, state: props.location.state}}
                        >
                            <i className='ni ni-bold-right'></i>
                        </Link> : ''}
                    </div>
                </div>
                <div className='recipesAndImagesContainer'>
                    <div className={nutrayDetectionsContainerClass}>
                        { blobUrl ? 
                            <WKTVisualizer 
                                src={blobUrl}
                                areas={detections}
                                onBboxClick={canAddEmbeddings ? handleDetectionClick : null}
                                fill={true}
                                showColorOnlyWhenHovering={true}
                            ></WKTVisualizer>
                        : '' }
                        <p className='mt-4'>{detectedRecipeListMessage}</p>
                        <ListGroup>
                            {detectedRecipes}
                        </ListGroup>
                    </div>
                    {
                        details.edit_detections.length <= 0 ? '' :

                        <div className='operatorDetectionsContainer'>
                            { blobUrl ? 
                                <WKTVisualizer 
                                    src={blobUrl}
                                    areas={edit_detections}
                                    onBboxClick={canAddEmbeddings ? handleDetectionClick : null}
                                    fill={true}
                                    showColorOnlyWhenHovering={true}
                                ></WKTVisualizer>
                            : '' }
                            <p className='mt-4'>{editedRecipeListMessage}</p>
                            <ListGroup>
                                {editedRecipes}
                            </ListGroup>
                        </div>
                    }
                </div>
            </div>
        </div>
        { details.site_id ? 
            <ReassignRecipeModal
                isOpen={canAddEmbeddings && selectedDetection !== null}
                toggle={() => setSelectedDetection(null)}
                location={details.site_id}
                onReassign={(recipe) => handleModalAddEmbedding(recipe.value)}
                defaultSelected={map_specific_recipe_to_generic(selectedDetection?.recipe_id)}/>
        : ''}
        </>
    );
}