import { ConfigContext } from "context";
import { useCallback, useContext, useEffect } from "react";
import Select from "react-select";
import { Button, Col, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";
import produce from "immer";
import css from "./RoleSelectorItem.module.css";
import { strings } from "localization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AVAILABLE_CONFIGS } from "hooks/useConfig";


const customSelectStyles = {
    control: (provided, state) => ({
        ...provided,
        minHeight: '43px',
        borderRadius: '0.375rem'
    })
};

function renderOptions(options) {
    return options.map((opt) => (
        <option key={opt.value} value={opt.value}>{opt.label}</option>
    ));
}

export default function RoleSelectorItem(props) {

    const config = useContext(ConfigContext);

    const requestConfigUpdate = config.requestConfigUpdate;
    useEffect(() => {
        requestConfigUpdate([AVAILABLE_CONFIGS.ROLES]);
    }, [requestConfigUpdate]);

    const roles = config.roles[props.state.selectedLocation.value];

    const onStateChange = props.onStateChange;

    const handleLocationChange = useCallback((e) => {
        const newState = produce(props.state, draft => {
            draft.selectedLocation = props.availableLocations.filter((l) => l.value === e.target.value)[0];
            const role_ids = config.roles[draft.selectedLocation.value].map((r) => r.value);
            draft.selectedRoles = draft.selectedRoles.filter((r) => role_ids.includes(r.value));
        });
        onStateChange(newState);
    }, [props.availableLocations, config.roles, props.state, onStateChange]);

    const handleRoleChange = useCallback((newRoles) => {
        const newState = produce(props.state, draft => {
            draft.selectedRoles = newRoles;
        });
        onStateChange(newState);
    }, [props.state, onStateChange]);

    return (
        <Row>
            <Col md="6">
                <FormGroup>
                    {props.showLabels && <Label>{strings.roleSelectorItem__canteenFieldLabel}</Label>}
                    <InputGroup>
                        <Button className={css.deleteRowButton} color="danger" onClick={() => props.onDelete()}>
                            <FontAwesomeIcon icon="trash" />
                        </Button>
                        <Input type="select" value={props.state.selectedLocation.value} onChange={(e) => handleLocationChange(e)}>
                            {renderOptions(props.availableLocations)}
                        </Input>
                    </InputGroup>
                </FormGroup>
            </Col>
            <Col md="6">
                <FormGroup>
                    {props.showLabels && <Label>{strings.roleSelectorItem__rolesFieldLabel}</Label>}
                    <Select
                        isMulti
                        styles={customSelectStyles}
                        closeMenuOnSelect={false}
                        value={props.state.selectedRoles}
                        onChange={(r) => handleRoleChange(r)}
                        options={roles}
                        placeholder={strings.roleSelectorItem__rolesFieldPlaceholder}
                    />
                </FormGroup>
            </Col>
        </Row>
    );
}