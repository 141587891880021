import FileInput from 'components/FileInput/FileInput';
import Header from 'components/Headers/Header';
import { ConfigContext } from 'context';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { postModel } from 'services/api';
import { toastError } from 'utils/utils';
import { toastSuccess } from 'utils/utils';
import { handleAxiosError } from 'utils/utils';
import Select from 'react-select';
import css from './ModelUploadPage.module.css';
import { strings } from 'localization';
import { AVAILABLE_CONFIGS } from 'hooks/useConfig';

export default function ModelUploadPage() {
    const [modelFile, setModelFile] = useState(null);
    const [labelFile, setLabelFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const modelFileInputRef = useRef(null);
    const labelFileInputRef = useRef(null);

    const config = useContext(ConfigContext);

    const requestConfigUpdate = config.requestConfigUpdate;
    useEffect(() => {
        requestConfigUpdate([AVAILABLE_CONFIGS.CANTEENS, AVAILABLE_CONFIGS.MODEL_TYPES]);
    }, [requestConfigUpdate]);

    const [location, setLocation] = useState(config.canteens[0]);
    const [modelType, setModelType] = useState(config.model_types[location.value][0]);
    const [version, setVersion] = useState('');

    async function saveChanges() {
        if (modelFile === null) {
            toastError(strings.modelUplaodPage__toastError__missingModelFile);
            return;
        }

        if (labelFile === null && modelType.value !== 'embedding') {
            toastError(strings.modelUploadPage__toastError__missingLabelFile);
            return;
        }

        const labelFileToSend = modelType.value === 'embedding' ? null : labelFile;

        if (version === null || version === '') {
            toastError(strings.modelUploadPage__toastError__missingVersion);
            return;
        }

        try {
            setUploading(true);
            await postModel(modelFile, labelFileToSend, location.value, modelType.value, version);
            modelFileInputRef.current.reset();
            labelFileInputRef.current.reset();
            setModelFile(null);
            setLabelFile(null);
            setVersion('');
            setLocation(config.canteens[0]);
            setModelType(config.model_types[0]);
            toastSuccess(strings.modelUploadPage__toastSuccess__uploadSuccess);
        } catch (error) {
            handleAxiosError(error, {}, strings.modelUploadPage__toastError__genericApiError);
        } finally {
            setUploading(false);
        }
    }

    return (
        <>
            <Header />
            <div className={css.pageContainer}>
                <div className={css.formContainer}>
                    <Form>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Label for='locationSelect'>{strings.modelUploadPage__locationFieldLabel}</Label>
                                    <Select
                                        id='locationSelect'
                                        value={location}
                                        onChange={(l) => setLocation(l)}
                                        options={config.canteens}
                                        placeholder={strings.modelUploadPage__locationFieldPlaceholder}
                                    />
                                    {/*{locationErrors.length > 0 ? (<p className={css.errorMessage}>{locationErrors[0]}</p>) : ''}*/}
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label for='modelTypeSelect'>{strings.modelUploadPage__modelTypeFieldLabel}</Label>
                                    <Select
                                        id='modelTypeSelect'
                                        value={modelType}
                                        onChange={(mt) => setModelType(mt)}
                                        options={config.model_types[location.value]}
                                        placeholder={strings.modelUploadPage__modelTypeFieldPlaceholder}
                                    />
                                    {/*{locationErrors.length > 0 ? (<p className={css.errorMessage}>{locationErrors[0]}</p>) : ''}*/}
                                </FormGroup>
                            </Col>
                            <Col md="2">
                                <FormGroup>
                                    <Label for='versionInput'>{strings.modelUploadPage__versionFieldLabel}</Label>
                                    <Input
                                        id='versionInput'
                                        type='text'
                                        value={version}
                                        onChange={(e) => setVersion(e.target.value)}
                                    />
                                    {/*{locationErrors.length > 0 ? (<p className={css.errorMessage}>{locationErrors[0]}</p>) : ''}*/}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Label for='modelFileInput'>{strings.modelUploadPage__modelFieldLabel}</Label>
                                    <FileInput
                                        ref={modelFileInputRef}
                                        id="modelFileInput"
                                        label={strings.modelUploadPage__modelButtonLabel}
                                        onChange={(file) => setModelFile(file)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Label for='labelFileInput'>{strings.modelUploadPage__labelsFieldLabel}</Label>
                                    <FileInput
                                        ref={labelFileInputRef}
                                        id="labelFileInput"
                                        label={strings.modelUploadPage__labelsButtonLabel}
                                        onChange={(file) => setLabelFile(file)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <Button
                                        disabled={uploading}
                                        color="primary"
                                        onClick={() => saveChanges()}
                                    >{strings.modelUploadPage__saveButtonLabel}</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    );
}
