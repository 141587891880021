import css from './TrayManagementInfoCard.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody, CardTitle, CardImg, Button } from 'reactstrap';
import { formatLongDateAndTime } from 'utils/utils';
import { strings } from 'localization';
import { useEffect, useState } from 'react';
import { getImage } from 'services/api';
import { usePrevious } from 'hooks/usePrevious';

export default function TrayManagementInfoCard(props) {

    const dateTime = formatLongDateAndTime(props.date);
    const dateTimeStr = strings.formatString(strings.trayManagementInfoCard__title, {
        date: dateTime['day'], 
        time: dateTime['time']
    });

    const [blobUrl, setBlobUrl] = useState('#');
    const previousSrc = usePrevious(props.thumbnail_url);

    useEffect(() => {
        async function fetchImage() {
            try {
                const response = await getImage(props.thumbnail_url);
                setBlobUrl(URL.createObjectURL(response.data));
            } catch (error) {
                console.error(error);
            }
        }

        if (props.thumbnail_url !== previousSrc) {
            fetchImage();
        }

        return function cleanup() {
            URL.revokeObjectURL(blobUrl);
        }
    }, [props.thumbnail_url, blobUrl, previousSrc]);

    return (
        <Card color="light" outline>
            <CardImg
                alt=""
                src={blobUrl}
                top
                width="100%"
            />
            <CardBody>
                <CardTitle tag="h5">
                    {dateTimeStr}
                </CardTitle>
                <div className={css.cardBottomBar}>
                    <div className={css.additionsDeletionsContainer}>
                        <span title={strings.trayManagementInfoCard__additionsToolTip} className={css.additions}><FontAwesomeIcon icon='circle-plus'/> {props.additions}</span>
                        <span title={strings.trayManagementInfoCard__deletionsToolTip} className={css.deletions}><FontAwesomeIcon icon='circle-minus'/> {props.deletions}</span>
                    </div>
                    <Button color="primary" onClick={() => props.onClick()}>
                        {strings.trayManagementInfoCard__correctButtonLabel}
                    </Button>
                </div>
            </CardBody>
        </Card>
    );
}