import { registerLocale } from "react-datepicker";
import it_IT from "date-fns/locale/it";
import en_US from "date-fns/locale/en-US";

export const ALL_RECIPES = {value: 'ALL_RECIPES', label: 'Tutte le ricette'};

export const COORD_FORMATS = {WKT: 'WKT', LEGACY: 'LEGACY'};

export const DISH_ID = 'dish';

export const FEATURES = {
    MANAGE_EMBEDDINGS: "MANAGE_EMBEDDINGS",
    MANAGE_USERS: "MANAGE_USERS",
    UPLOAD_APK: "UPLOAD_APK",
    UPLOAD_MODELS: "UPLOAD_MODELS",
    CREATE_ADMIN: "CREATE_ADMIN",
    VISUALIZE_LOGS: "VISUALIZE_LOGS",
    ADD_EXISTING_EMBEDDINGS: "ADD_EXISTING_EMBEDDINGS",
    MANAGE_TRAYS: "MANAGE_TRAYS"
}

export const SEARCH_MODAL_FILTERS = {
    LOCATION_FILTER: "LOCATION_FILTER",
    CATEGORY_FILTER: "CATEGORY_FILTER",
    RECIPE_FILTER: "RECIPE_FILTER",
    DATE_TIME_FILTER: "DATE_TIME_FILTER",
    ERROR_FILTER: "ERROR_FILTER"
}

export const TIME_INTERVALS = 15;

export const DATE_FORMATS = {
  'it-IT': 'dd/MM/yyyy',
  'en-US': 'MM/dd/yyyy'
}

export const TIME_FORMATS = {
  'it-IT': 'H:mm',
  'en-US': 'h:mm aa'
}

registerLocale('it-IT', it_IT);
registerLocale('en-US', en_US);
