import React from 'react';

import { Pagination as PaginationRS, PaginationItem, PaginationLink } from "reactstrap";

export default
class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleKeyDown(e) {
    if (e.repeat) return;
    switch (e.which) {
      case 37:
        // left arrow pressed
        this.previousPage();
        break;
      case 39:
        // right arrow pressed
        this.nextPage();
        break;
      default:
        break;
    }
  }

  componentDidMount() {
    if (this.props.useKeyboard) {
      document.addEventListener("keydown", this.handleKeyDown, false);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown, false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.numItems !== this.props.numItems || prevProps.perPage !== this.props.perPage) {
      
      const newPageCount = this.getPageCount();
      
      if (this.state.currentPage > newPageCount) {
        this.setState({
          currentPage: newPageCount
        });

        this.onPageChange(newPageCount);
        return;
      }

      if (this.state.currentPage === 0) {
        this.setState({
          currentPage: 1
        });

        this.onPageChange(1);
        return;
      }

      if (prevProps.perPage !== this.props.perPage) {
        this.onPageChange(this.state.currentPage);
      }
    }
  }

  onPageChange(currentPage) {
    let start = this.props.perPage * (currentPage - 1);
    let end = this.props.perPage * (currentPage - 1) + this.props.perPage;
    end = end > this.props.numItems ? this.props.numItems : end;
    if (start < 0) return;
    this.props.onPageChange(start, end);
  }

  goToPage(page) {
    const newPage = page !== '...' ? page : this.state.currentPage;

    if (this.state.currentPage !== newPage) {
      this.setState({
        currentPage: newPage
      });
  
      this.onPageChange(newPage);
    }
  }

  nextPage() {
    const pageCount = this.getPageCount();
    const newPage = this.state.currentPage < pageCount ? this.state.currentPage + 1 : this.state.currentPage;

    this.setState({
      currentPage: newPage
    });

    this.onPageChange(newPage);
  }

  previousPage() {
    const newPage = this.state.currentPage > 1 ? this.state.currentPage - 1 : this.state.currentPage;

    this.setState({
      currentPage: newPage
    });

    this.onPageChange(newPage);
  }

  renderButton(btn, index) {
    const isActive = this.isCurrentPage(btn);

    const attributes = {};
    if (isActive) {
      attributes['active'] = true;
    }

    if (btn === '...') {
      attributes['disabled'] = true;
    }

    return (
      <PaginationItem { ...attributes } key={index}>
        <PaginationLink onClick={() => this.goToPage(btn)}>
          {btn}
        </PaginationLink>
      </PaginationItem>
    );
  }

  isCurrentPage(btn) {
    return this.state.currentPage === btn;
  }

  getPageCount() {
    return Math.ceil(this.props.numItems / this.props.perPage);
  }

  getButtons() {
    const btns = [];

    const btn_count = 5 + 2 * this.props.surroundingButtons;

    const pageCount = this.getPageCount();

    if (pageCount <= btn_count) {
      for (let i = 1; i <= pageCount; i++) {
        btns.push(i);
      }
      return btns.map((b, i) => this.renderButton(b, i));
    }

    btns.push(1);

    if (this.state.currentPage - this.props.surroundingButtons > 3) {
      btns.push('...');
    } else {
      btns.push(2);
    }

    for (let i = 3; i <= 3 + this.props.surroundingButtons * 2; i++) {
      let offset = (this.state.currentPage - Math.ceil(btn_count / 2));
      offset = Math.max(0, offset);
      offset = Math.min(offset, pageCount - 2 - this.props.surroundingButtons - Math.ceil(btn_count / 2));
      btns.push(i + offset);
    }

    if (this.state.currentPage + this.props.surroundingButtons < pageCount - 2) {
      btns.push("...");
    } else {
      btns.push(pageCount - 1);
    }

    btns.push(pageCount);

    return btns.map((b, i) => this.renderButton(b, i));
  }

  render() {
    const buttons = this.getButtons();

    const pageCount = this.getPageCount();
    const isPrevEnabled = this.state.currentPage > 1;
    const isNextEnabled = this.state.currentPage < pageCount;

    const prevButtonAttributes = {};
    if (!isPrevEnabled) {
      prevButtonAttributes['disabled'] = true;
    }

    const nextButtonAttributes = {};
    if (!isNextEnabled) {
      nextButtonAttributes['disabled'] = true;
    }

    return (
      <PaginationRS>
        
        <PaginationItem { ...prevButtonAttributes }>
          <PaginationLink
            aria-label="Previous"
            onClick={() => this.previousPage()}
          >
            <i className="fa fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>

        {buttons}

        <PaginationItem { ...nextButtonAttributes }>
          <PaginationLink
            aria-label="Next"
            onClick={() => this.nextPage()}
          >
            <i className="fa fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>

      </PaginationRS>
    );
  }
}