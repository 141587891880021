import LogModal from 'components/LogModal/LogModal';
import { ConfigContext } from 'context';
import { AVAILABLE_CONFIGS } from 'hooks/useConfig';
import { strings } from 'localization';
import { useContext, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import css from "./SystemLogsTable.module.css";

const MAX_MESSAGE_LEN = 100;

function renderTime(iso8601_timestamp) {
    const date_obj = new Date(iso8601_timestamp);
    return date_obj.toLocaleTimeString(strings.locale);
}

function renderLogType(logType, logTypes) {
    return logTypes.filter((l) => l.value === logType)[0].label;
}

function renderMessage(msg, selectLog) {
    let newMsg = msg.substring(0, Math.min(MAX_MESSAGE_LEN - 3, msg.length));
    if (newMsg !== msg) newMsg += '...';
    return <a href='#d' onClick={(e) => {e.preventDefault(); selectLog()}}>{newMsg}</a>;
}

function renderRow(log, logTypes, selectLog) {
    return (
        <tr key={log.pk}>
            <td className={css.tableCell}>{renderTime(log.date)}</td>
            <td className={css.tableCell}>{renderLogType(log.category, logTypes)}</td>
            <td className={css.tableCell}>{log.user}</td>
            <td className={css.tableCell}>{renderMessage(log.message, selectLog)}</td>
        </tr>
    );
}

function renderRows(users, logTypes, selectLog) {
    return users.map((log) => renderRow(log, logTypes, () => selectLog(log)));
}

export default function SystemLogsTable(props) {
    const config = useContext(ConfigContext);
    
    const requestConfigUpdate = config.requestConfigUpdate;
    
    useEffect(() => {
        requestConfigUpdate([AVAILABLE_CONFIGS.SYSTEM_LOGS_CATEGORIES]);
    }, [requestConfigUpdate]);

    const logTypes = config.system_logs_categories;

    const [selectedLog, setSelectedLog] = useState(null);

    return (
        <>
            <Table className='align-items-center' responsive>
                <thead className='thead-light'>
                    <tr>
                        <th scope="col">{strings.systemLogsTable__timeColumnName}</th>
                        <th scope="col">{strings.systemLogsTable__typeColumnName}</th>
                        <th scope="col">{strings.systemLogsTable__usernameColumnName}</th>
                        <th scope="col">{strings.systemLogsTable__messageColumnName}</th>
                    </tr>
                </thead>
                <tbody>
                    {renderRows(props.logs, logTypes, setSelectedLog)}
                </tbody>
            </Table>

            <LogModal
                log={selectedLog}
                isOpen={selectedLog !== null}
                toggle={() => setSelectedLog(null)}
            />
        </>
    );
}
