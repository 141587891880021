import { ConfigContext } from "context";
import { useContext, useEffect } from "react";
import { AVAILABLE_CONFIGS } from "./useConfig";
import { useQueryFilters } from "./useQueryFilters";

export function useCategories(overrideLocation = null) {
    const config = useContext(ConfigContext);
    
    const requestConfigUpdate = config.requestConfigUpdate;
    useEffect(() => {
        requestConfigUpdate([AVAILABLE_CONFIGS.CATEGORIES]);
    }, [requestConfigUpdate]);
    
    const [{queryLocation}, ] = useQueryFilters();
    const location = overrideLocation ?? queryLocation;
    return config.categories[location];
}
