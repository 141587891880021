import { useAllowedFilters } from "hooks/useAllowedFilters";
import css from "./AdminNavbar.module.css";
import { strings } from "localization";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { auth } from "services/api";

const AdminNavbar = (props) => {
  const history = useHistory();

  const allowedFilters = useAllowedFilters();

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <p
            className="h4 mb-0 text-white text-uppercase d-none d-md-inline-block"
          >
            {props.brandText}
          </p>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            { allowedFilters.size !== 0 ?
            <Link to="#" className={css.filterLink + ' mb-0 text-sm font-weight-bold'} onClick={(e) => props.toggleSearchModal(e)}>{strings.adminNavbar__filterButtonLabel}</Link>
            : ''}
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="ml-2 d-none d-md-block">
                  <span className="mb-0 text-sm font-weight-bold">
                    <i className="ni ni-single-02" />
                    {' ' + auth.getUsername()}
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                {/* NOTA: LE MODIFICHE FATTE QUI VANNO RIPORTATE ANCHE IN Sidebar.js
                    Per funzionare su smartphone */}
                <DropdownItem to={{ pathname: "/admin/changepsw", search: history.location.search }} tag={Link}>
                  <i className="ni ni-key-25" />
                  <span>{strings.adminNavbar__changePasswordDropdownButtonLabel}</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#" onClick={(e) => {
                    e.preventDefault();
                    auth.logout();
                    history.push({pathname: '/auth/login'});
                  }}>
                  <i className="ni ni-user-run" />
                  <span>{strings.adminNavbar__logoutDropdownButtonLabel}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
