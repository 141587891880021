import { strings } from "localization";
import { useCallback, useState } from "react";
import { Button, Col, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { sendForgotPassword } from "services/api";
import { toastSuccess } from "utils/utils";
import { handleAxiosError } from "utils/utils";


export default function ForgotPasswordModal(props) {
    const [email, setEmail] = useState('');
    const [emailErrors, setEmailErrors] = useState([]);

    const toggle = props.toggle;

    const handleConfirm = useCallback(async () => {
        try {
            await sendForgotPassword(email);
            toastSuccess(strings.forgotPasswordModal__toastSuccess__pswResetRequestSent);
            toggle();
        } catch (error) {
            const handlers = {
                404: () => {
                    setEmailErrors([strings.forgotPasswordModal__toastError__email404]);
                }
            };
            handleAxiosError(error, handlers, strings.forgotPasswordModal__toastError__genericAxiosError)
        }
    }, [email, toggle]);

    return (
        <Modal isOpen={props.isOpen}>
            <ModalHeader toggle={() => {setEmailErrors([]); props.toggle()}}>
                {strings.forgotPasswordModal__title}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="ni ni-email-83" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    placeholder={strings.forgotPasswordModal__emailFieldPlaceholder}
                                    type="email"
                                    autoComplete="current-password"
                                    invalid={emailErrors.length > 0}
                                    onChange={(e) => {setEmailErrors([]); setEmail(e.target.value)}}
                                    />
                                <FormFeedback>
                                    <span className="ml-1">{emailErrors[0]}</span>
                                </FormFeedback>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" disabled={email === ''} onClick={() => handleConfirm(email)}>
                    {strings.forgotPasswordModal__confirmButtonLabel}
                </Button>
                <Button color="secondary" onClick={() => {setEmailErrors([]); props.toggle()}}>
                    {strings.forgotPasswordModal__cancelButtonLabel}
                </Button>
            </ModalFooter>
        </Modal>
    );
}