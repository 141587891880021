import './Gallery.css';

import React from 'react';
import GalleryImage from "components/GalleryImage/GalleryImage.js";

export default
class Gallery extends React.Component {  

  render() {
    const images = [];
    for (const img of this.props.images) {
      images.push(
        <GalleryImage
          key={img.image_id}
          link={img.link}
          src={img.thumbnail_url}>
        </GalleryImage>
      );
    }

    return (
      <div className='gallery-container'>
        {images}
      </div>
    );
  }
}