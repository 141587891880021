import produce from "immer";
import { strings } from "localization";
import { useCallback, useEffect, useState } from "react";
import { getConfigDeviceTypes } from "services/api";
import { getConfigRoles } from "services/api";
import { getConfigSeasons } from "services/api";
import { getConfigSpecificToGenericMap } from "services/api";
import { getConfigSystemLogCategories } from "services/api";
import { getConfigFeatures } from "services/api";
import { getConfigModelTypes } from "services/api";
import { getAllRecipes } from "services/api";
import { getConfigCategories } from "services/api";
import { getConfigCanteens } from "services/api";
import { handleAxiosError } from "utils/utils";

// CONFIGURATION
export const AVAILABLE_CONFIGS = {
    CANTEENS: "canteens",
    SEASONS: "seasons",
    CATEGORIES: "categories",
    RECIPES: "recipes",
    MODEL_TYPES: "model_types",
    DEVICE_TYPES: "device_types",
    FEATURES: "features",
    ROLES: "roles",
    SYSTEM_LOGS_CATEGORIES: "system_logs_categories",
    SPECIFIC_TO_GENERIC_MAP: "specific_to_generic_map"
}

const FETCHERS = {
    [AVAILABLE_CONFIGS.CANTEENS]: getConfigCanteens,
    [AVAILABLE_CONFIGS.SEASONS]: getConfigSeasons,
    [AVAILABLE_CONFIGS.CATEGORIES]: getConfigCategories,
    [AVAILABLE_CONFIGS.RECIPES]: getAllRecipes,
    [AVAILABLE_CONFIGS.MODEL_TYPES]: getConfigModelTypes,
    [AVAILABLE_CONFIGS.DEVICE_TYPES]: getConfigDeviceTypes,
    [AVAILABLE_CONFIGS.FEATURES]: getConfigFeatures,
    [AVAILABLE_CONFIGS.ROLES]: getConfigRoles,
    [AVAILABLE_CONFIGS.SYSTEM_LOGS_CATEGORIES]: getConfigSystemLogCategories,
    [AVAILABLE_CONFIGS.SPECIFIC_TO_GENERIC_MAP]: getConfigSpecificToGenericMap
}

const POSTPROCESSORS = {
    [AVAILABLE_CONFIGS.CANTEENS]: (c) => c,
    [AVAILABLE_CONFIGS.SEASONS]: (s) => s,
    [AVAILABLE_CONFIGS.CATEGORIES]: (c) => c,
    [AVAILABLE_CONFIGS.RECIPES]: (r) => sortRecipes(r),
    [AVAILABLE_CONFIGS.MODEL_TYPES]: (mt) => mt,
    [AVAILABLE_CONFIGS.DEVICE_TYPES]: (dt) => dt,
    [AVAILABLE_CONFIGS.FEATURES]: (f) => new Set(f),
    [AVAILABLE_CONFIGS.ROLES]: (r) => r,
    [AVAILABLE_CONFIGS.SYSTEM_LOGS_CATEGORIES]: (c) => c,
    [AVAILABLE_CONFIGS.SPECIFIC_TO_GENERIC_MAP]: (m) => m
}

// POST PROCESSING FUNCTIONS
function sortRecipes(r) {
    return r.sort((a, b) => {
        if (a['recipe_name'].toLowerCase() < b['recipe_name'].toLowerCase()) return -1;
        if (a['recipe_name'].toLowerCase() > b['recipe_name'].toLowerCase()) return 1;
        return 0;
    });
}


// MAIN HOOK CODE
const configSet = new Set(Object.entries(AVAILABLE_CONFIGS).map(([k, v]) => v));

function validate(requestedConfigs) {
    const isValid = requestedConfigs.map((x) => configSet.has(x)).reduce((prev, curr) => prev && curr, true);
    if (!isValid) throw new Error("requestedConfigs contains some invalid config identifiers");
}

export function useConfig(defaultRequestedConfigs) {
    validate(defaultRequestedConfigs);
    defaultRequestedConfigs = new Set(defaultRequestedConfigs);
    
    const [isLoading, setIsLoading] = useState(false);
    const [requestQueue, setRequestQueue] = useState([{
        requestedConfigs: defaultRequestedConfigs,
        onFinishUpdate: () => {}
    }]);
    const [configs, setConfigs] = useState({});

    useEffect(() => {
        if (isLoading) return;
        if (requestQueue.length === 0) return;
        setIsLoading(true);

        let requestedConfigs = new Set([]);
        let onFinishUpdateCallbacks = [];

        for (const task of requestQueue) {
            requestedConfigs = new Set([...requestedConfigs, ...task.requestedConfigs]);
            onFinishUpdateCallbacks.push(task.onFinishUpdate);
        }

        setRequestQueue([]);

        async function fetchConfigs(reqCfg) {
            try {
                console.log('updating configs: {' + [...reqCfg].join(', ') + '}');

                const fetchPromises = [];
                const fetchPromisesIds = [];

                for (const cfgId of reqCfg) {
                    fetchPromises.push(FETCHERS[cfgId]());
                    fetchPromisesIds.push(cfgId);
                }

                const results = await Promise.all(fetchPromises);

                const newConfigs = produce(configs, draft => {
                    for (let i = 0; i < results.length; i++) {
                        const result = results[i];
                        const cfgId = fetchPromisesIds[i];
                        draft[cfgId] = POSTPROCESSORS[cfgId](result.data);
                    }
                });

                setConfigs(newConfigs);
            } catch (error) {
                handleAxiosError(error, {}, strings.adminLayout__genericAllRecipesApiError); // TODO: CAMBIARE ERRORE
            } finally {
                for (const onFinishUpdate of onFinishUpdateCallbacks) {
                    onFinishUpdate();
                }
                setIsLoading(false);
            }
        }
        
        fetchConfigs(requestedConfigs);
    }, [isLoading, requestQueue, configs]);

    const requestConfigUpdate = useCallback((requested, onFinishUpdate = () => {}) => {
        validate(requested);

        setRequestQueue(oldRequestQueue => produce(oldRequestQueue, draft => {
            draft.push({
                requestedConfigs: new Set(requested),
                onFinishUpdate
            });
        }));
    }, []);

    return [configs, requestConfigUpdate];
}
