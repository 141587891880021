import { ConfigContext } from "context";
import { useContext, useEffect } from "react";
import { useQueryState } from "react-router-use-location-state";
import { ALL_RECIPES } from "utils/constants";
import { getDefaultFromAndToTimes } from "utils/utils";
import { AVAILABLE_CONFIGS } from "./useConfig";

export function useQueryFilters() {

    const context = useContext(ConfigContext);

    const requestConfigUpdate = context.requestConfigUpdate;
    useEffect(() => {
        requestConfigUpdate([AVAILABLE_CONFIGS.CANTEENS, AVAILABLE_CONFIGS.CATEGORIES]);
    }, [requestConfigUpdate]);

    const [defaultFrom, defaultTo] = getDefaultFromAndToTimes();

    const [queryLocation, setQueryLocation ] = useQueryState('location', context.canteens[0].value);
    const [queryCategory, setQueryCategory ] = useQueryState('category', context.categories[context.canteens[0].value][0].value);
    const [queryRecipe, setQueryRecipe ] = useQueryState('recipe', ALL_RECIPES.value);
    const [queryFrom, setQueryFrom ] = useQueryState('from', defaultFrom);
    const [queryTo, setQueryTo] = useQueryState('to', defaultTo);
    const [queryPage, setQueryPage] = useQueryState('page', 1);
    const [queryErrorFilter, setQueryErrorFilter ] = useQueryState('errorFilter', false);
    const [queryEnableDateFilter, setQueryEnableDateFilter ] = useQueryState('enableDateFilter', true);

    return [
        { queryLocation, queryCategory, queryRecipe, queryFrom, queryTo, queryPage, queryErrorFilter, queryEnableDateFilter },
        { setQueryLocation, setQueryCategory, setQueryRecipe, setQueryFrom, setQueryTo, setQueryPage, setQueryErrorFilter, setQueryEnableDateFilter }
    ];
}