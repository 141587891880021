import RoleSelectorItem from "components/RoleSelectorItem/RoleSelectorItem";
import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Button, Col, FormGroup, Row } from "reactstrap";
import produce from "immer";
import { ConfigContext } from "context";
import { strings } from "localization";
import { AVAILABLE_CONFIGS } from "hooks/useConfig";

function buildOnChangeMessage(newState) {
    const message = {};
    for (const rowState of newState) {
        message[rowState.selectedLocation.value] = rowState.selectedRoles.map((r) => r.value);
    }
    return message;
}

function renderItems(state, availableLocations, removeRow, onStateChange) {
    return state.map((rowState, i) => (
        <RoleSelectorItem
            key={i}
            state={rowState}
            showLabels={i===0}
            onDelete={() => removeRow(i)}
            availableLocations={[rowState.selectedLocation, ...availableLocations]}
            onStateChange={(newState) => onStateChange(i, newState)}
        />
    ));
}

export default forwardRef((props, ref) => {
    
    const config = useContext(ConfigContext);

    const requestConfigUpdate = config.requestConfigUpdate;
    useEffect(() => {
        requestConfigUpdate([AVAILABLE_CONFIGS.CANTEENS, AVAILABLE_CONFIGS.ROLES]);
    }, [requestConfigUpdate]);

    const [state, setState] = useState([]);

    const availableLocations = useMemo(() => {
        const usedLocationIDs = new Set([]);
        for (const rowState of state) {
            usedLocationIDs.add(rowState.selectedLocation.value);
        }
        return config.canteens.filter((loc) => !usedLocationIDs.has(loc.value));
    }, [config.canteens, state]);

    function initialize(roles) {
        const newState = [];
        for (const location_id in roles) {
            const roleSet = new Set(roles[location_id]);
            newState.push({
                selectedLocation: availableLocations.filter((loc) => loc.value === location_id)[0],
                selectedRoles: config.roles[location_id].filter((r) => roleSet.has(r.value))
            });
        }
        setState(newState);
    }

    useImperativeHandle(ref, () => ({
        initialize
    }));

    const addRow = useCallback(() => {
        if (availableLocations.length === 0) return;
        const nextState = produce(state, draft => {
            draft.push({
                selectedLocation: availableLocations[0],
                selectedRoles: []
            });
        });
        setState(nextState);
    }, [state, availableLocations]);

    const removeRow = useCallback((i) => {
        const newState = state.filter((rowState, idx) => (i !== idx));
        setState(newState);
    }, [state]);

    const onStateChange = useCallback((i, newState) => {
        const nextState = produce(state, draft => {
            draft[i] = newState;
        });
        setState(nextState);
    }, [state]);

    const onChange = props.onChange;
    useEffect(() => {
        const message = buildOnChangeMessage(state);
        onChange(message);
    }, [state, onChange]);

    return (
        <>
            {renderItems(state, availableLocations, removeRow, onStateChange)}
            { availableLocations.length !== 0 ? 
            <Row>
                <Col md="12">
                    <FormGroup>
                        <Button color="primary" onClick={() => addRow()}>
                            {state.length === 0 ? strings.roleSelector__createRowButtonLabel__firstRow
                            : strings.roleSelector__createRowButtonLabel__successiveRows}
                        </Button>
                    </FormGroup>
                </Col>
            </Row> : '' }
        </>
    );
});
