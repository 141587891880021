/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import GalleryPage from "views/GalleryPage/GalleryPage";
import DetailsPage from "views/DetailsPage/DetailsPage.js";
import LoginPage from "views/LoginPage/LoginPage";
import StatisticsPage from "views/StatisticsPage/StatisticsPage";
import ChangePasswordPage from "views/ChangePasswordPage/ChangePasswordPage";
import ManagementPage from "views/ManagementPage/ManagementPage";
import SingleRecipeManagementPage from "views/SingleRecipeManagementPage/SingleRecipeManagementPage";
import ModelUploadPage from "views/ModelUploadPage/ModelUploadPage";
import APKUploadPage from "views/APKUploadPage/APKUploadPage";
import { FEATURES } from "utils/constants";
import { strings } from "localization";
import { SEARCH_MODAL_FILTERS } from "utils/constants";
import UserManagementPage from "views/UserManagementPage/UserManagementPage";
import SetPasswordPage from "views/SetPasswordPage/SetPasswordPage";
import SystemLogsPage from "views/SystemLogsPage/SystemLogsPage";
import TrayManagementPage from "views/TrayManagementPage/TrayManagementPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SingleTrayManagementPage from "views/SingleTrayManagementPage/SingleTrayManagementPage";
import DailyMenuPage from "views/DailyMenuPage/DailyMenuPage";


function enableIfAll(requiredFeatures) {
  return (featureSet) => {
    for (const requiredFeature of requiredFeatures) {
      if (!featureSet.has(requiredFeature)) return false;
    }
    return true;
  }
}

var routes = [
  {
    path: "/gallery",
    name: strings.routes__galleryPageName,
    icon: <i><FontAwesomeIcon icon="images"/></i>,
    component: GalleryPage,
    layout: "/admin",
    showInSidebar: true,
    allowedFilters: new Set([
      SEARCH_MODAL_FILTERS.LOCATION_FILTER,
      SEARCH_MODAL_FILTERS.CATEGORY_FILTER,
      SEARCH_MODAL_FILTERS.RECIPE_FILTER,
      SEARCH_MODAL_FILTERS.DATE_TIME_FILTER,
      SEARCH_MODAL_FILTERS.ERROR_FILTER
    ]),
    isEnabled: enableIfAll([])
  },
  {
    path: "/statistics",
    name: strings.routes__statisticsPageName,
    icon: <i><FontAwesomeIcon icon="chart-pie"/></i>,
    component: StatisticsPage,
    layout: "/admin",
    showInSidebar: true,
    allowedFilters: new Set([
      SEARCH_MODAL_FILTERS.LOCATION_FILTER,
      SEARCH_MODAL_FILTERS.DATE_TIME_FILTER
    ]),
    isEnabled: enableIfAll([])
  },
  {
    path: "/daily-menu",
    name: strings.routes__dailyMenuPageName,
    icon: <i><FontAwesomeIcon icon="book-open"/></i>,
    component: DailyMenuPage,
    layout: "/admin",
    showInSidebar: true,
    allowedFilters: new Set([
      SEARCH_MODAL_FILTERS.LOCATION_FILTER
    ]),
    isEnabled: enableIfAll([])
  },
  {
    path: "/management/:site/:id",
    name: strings.routes__singleRecipeManagementPageName,
    icon: <i><FontAwesomeIcon icon="database"/></i>,
    component: SingleRecipeManagementPage,
    layout: "/admin",
    showInSidebar: false,
    allowedFilters: new Set([]),
    isEnabled: enableIfAll([FEATURES.MANAGE_EMBEDDINGS])
  },
  {
    path: "/management",
    name: strings.routes__managementPageName,
    icon: <i><FontAwesomeIcon icon="database"/></i>,
    component: ManagementPage,
    layout: "/admin",
    showInSidebar: true,
    allowedFilters: new Set([
      SEARCH_MODAL_FILTERS.LOCATION_FILTER,
      SEARCH_MODAL_FILTERS.CATEGORY_FILTER
    ]),
    isEnabled: enableIfAll([FEATURES.MANAGE_EMBEDDINGS])
  },
  {
    path: "/user-management",
    name: strings.routes__userManagementPageName,
    icon: <i><FontAwesomeIcon icon="user-gear"/></i>,
    component: UserManagementPage,
    layout: "/admin",
    showInSidebar: true,
    allowedFilters: new Set([]),
    isEnabled: enableIfAll([FEATURES.MANAGE_USERS])
  },
  {
    path: "/tray-management/:id",
    name: strings.routes__singleTrayManagementPageName,
    icon: <i><FontAwesomeIcon icon="utensils"/></i>,
    component: SingleTrayManagementPage,
    layout: "/admin",
    showInSidebar: false,
    allowedFilters: new Set([]),
    isEnabled: enableIfAll([FEATURES.MANAGE_TRAYS])
  },
  {
    path: "/tray-management",
    name: strings.routes__trayManagementPageName,
    icon: <i><FontAwesomeIcon icon="utensils"/></i>,
    component: TrayManagementPage,
    layout: "/admin",
    showInSidebar: true,
    allowedFilters: new Set([SEARCH_MODAL_FILTERS.LOCATION_FILTER]),
    isEnabled: enableIfAll([FEATURES.MANAGE_TRAYS])
  },
  {
    path: "/model-upload",
    name: strings.routes__modelUploadPageName,
    icon: <i><FontAwesomeIcon icon="upload"/></i>,
    component: ModelUploadPage,
    layout: "/admin",
    showInSidebar: true,
    allowedFilters: new Set([]),
    isEnabled: enableIfAll([FEATURES.UPLOAD_MODELS])
  },
  {
    path: "/apk-upload",
    name: strings.routes__apkUploadPageName,
    icon: <i><FontAwesomeIcon icon="upload"/></i>,
    component: APKUploadPage,
    layout: "/admin",
    showInSidebar: true,
    allowedFilters: new Set([]),
    isEnabled: enableIfAll([FEATURES.UPLOAD_APK])
  },
  {
    path: "/systemlogs",
    name: strings.routes__systemLogsPageName,
    icon: <i><FontAwesomeIcon icon="file-circle-exclamation"/></i>,
    component: SystemLogsPage,
    layout: "/admin",
    showInSidebar: true,
    allowedFilters: new Set([]),
    isEnabled: enableIfAll([FEATURES.VISUALIZE_LOGS])
  },
  {
    path: "/details/:id",
    name: strings.routes__detailsPageName,
    icon: <i><FontAwesomeIcon icon="images"/></i>,
    component: DetailsPage,
    layout: "/admin",
    showInSidebar: false,
    allowedFilters: new Set([]),
    isEnabled: enableIfAll([])
  },
  {
    path: "/changepsw",
    name: strings.routes__changePasswordPageName,
    icon: <i className={"ni ni-key-24 text-info"}/>,
    component: ChangePasswordPage,
    layout: "/admin",
    showInSidebar: false,
    allowedFilters: new Set([]),
    isEnabled: enableIfAll([])
  },
  {
    path: "/login",
    name: strings.routes__loginPageName,
    icon: <i className={"ni ni-key-25 text-info"}/>,
    component: LoginPage,
    layout: "/auth",
    showInSidebar: false
  },
  {
    path: "/setpsw/:token",
    name: strings.routes__setpswPageName,
    icon: <i className={"ni ni-key-25 text-info"}/>,
    component: SetPasswordPage,
    layout: "/auth",
    showInSidebar: false
  }
];
export default routes;
