import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import routes from "routes";

function getAllowedFilters(pathname) {
    for (let i = 0; i < routes.length; i++) {
        if (
            pathname.indexOf(routes[i].layout + routes[i].path.split(':')[0]) !== -1
        ) {
            return routes[i].allowedFilters;
        }
    }
    return new Set([]);
};

export function useAllowedFilters() {
    const { pathname } = useLocation();

    const allowedFilters = useMemo(() => {
      return getAllowedFilters(pathname);
    }, [pathname]);

    return allowedFilters;
}
