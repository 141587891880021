import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteModal from 'components/DeleteModal/DeleteModal';
import Header from 'components/Headers/Header';
import ImageWithOverlay from 'components/ImageWithOverlay/ImageWithOverlay';
import { LinkWithQuery } from 'components/LinkWithQuery/LinkWithQuery';
import ReassignRecipeModal from 'components/ReassignRecipeModal/ReassignRecipeModal';
import { usePrevious } from 'hooks/usePrevious';
import { useQueryFilters } from 'hooks/useQueryFilters';
import { strings } from 'localization';
import { useCallback, useEffect, useRef, useState } from 'react';
import { reassignEmbedding } from 'services/api';
import { deleteEmbedding } from 'services/api';
import { getSingleEmbeddingInfo } from 'services/api';
import { toastSuccess } from 'utils/utils';
import { toastError } from 'utils/utils';
import { handleAxiosError } from 'utils/utils';
import css from './SingleRecipeManagementPage.module.css';


const ACTIONS = {
    EDIT: 'edit',
    DELETE: 'delete'
}


function renderImages(embeddingInfo, onEditOrDelete) {
    return embeddingInfo?.embeddings.map((emb, i) => (
        <div className={css.imageContainer} key={emb.embeddings_id + '_' + emb.crop_id}>
            <ImageWithOverlay src={emb.crop_img_url}>
                <div className={css.buttonBar}>
                    <button
                        className='btn btn-warning'
                        onClick={() => onEditOrDelete(i, ACTIONS.EDIT)}
                    ><FontAwesomeIcon icon="pen" /></button>
                    <button
                        className='btn btn-danger'
                        onClick={() => onEditOrDelete(i, ACTIONS.DELETE)}
                    ><FontAwesomeIcon icon="trash" /></button>
                </div>
            </ImageWithOverlay>
        </div>
    ));
}

function computePrevPage(embInfoIndices, embInfo, currEmbIdx) {
    if (! embInfoIndices?.length > 0) return [null, null];
    const newIdx = Math.max(0, currEmbIdx - 1);
    return [embInfo[embInfoIndices[newIdx]].recipe_id, newIdx];
}

function computeNextPage(embInfoIndices, embInfo, currEmbIdx) {
    if (! embInfoIndices?.length > 0) return [null, null];
    const newIdx = Math.min(embInfoIndices.length - 1, currEmbIdx + 1);
    return [embInfo[embInfoIndices[newIdx]].recipe_id, newIdx];
}

export default function SingleRecipeManagementPage(props) {
    const [embeddingInfo, setEmbeddingInfo] = useState(null);
    const [selectedEmbedding, setSelectedEmbedding] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);

    const [{queryLocation}, ] = useQueryFilters();

    const fetchSingleEmbeddingInfo = useCallback(async () => {
        try {
            const response = await getSingleEmbeddingInfo(queryLocation, props.match.params.id);
            setEmbeddingInfo(response.data);
        } catch (error) {
            handleAxiosError(error, {}, strings.singleRecipeManagementPage__toastError__genericSingeEmbeddingInfoApiError);
        }
    }, [queryLocation, props.match.params.id]);

    const unselect = useCallback(() => {
        setSelectedEmbedding(null);
        setSelectedAction(null);
    }, []);

    const handleEditOrDeleteButtonClick = useCallback((emb, action) => {
        setSelectedEmbedding(emb);
        setSelectedAction(action);
    }, []);

    useEffect(() => {
        fetchSingleEmbeddingInfo();
    }, [fetchSingleEmbeddingInfo]);

    const [prevPage, prevPageIdx] = computePrevPage(props.location.state?.embInfoIndices, props.location.state?.embInfo, props.location.state?.currEmbIndex);
    const [nextPage, nextPageIdx] = computeNextPage(props.location.state?.embInfoIndices, props.location.state?.embInfo, props.location.state?.currEmbIndex);

    const handleModalDelete = useCallback(async () => {
        const selectedEmb = embeddingInfo.embeddings[selectedEmbedding];
        const recipe_id = embeddingInfo.recipe_id;
        const embeddings_id = selectedEmb.embeddings_id;
        const crop_id = selectedEmb.crop_id;

        const newEmbeddingInfo = {
            ...embeddingInfo,
            embeddings: embeddingInfo.embeddings.filter((emb, i) => i !== selectedEmbedding),
            number_of_embeddings: embeddingInfo.number_of_embeddings - 1
        };

        try {
            await deleteEmbedding(queryLocation, recipe_id, embeddings_id, crop_id);
            setEmbeddingInfo(newEmbeddingInfo);
            unselect();
            await fetchSingleEmbeddingInfo();
            toastSuccess(strings.singleRecipeManagementPage__toastSuccess__deletedExample);
        } catch (error) {
            handleAxiosError(error, {}, strings.singleRecipeManagementPage__toastError__genericDeleteEmbeddingApiError);
        }
    }, [embeddingInfo, queryLocation, selectedEmbedding, unselect, fetchSingleEmbeddingInfo]);

    const handleModalReassign = useCallback(async (newRecipeId) => {
        const selectedEmb = embeddingInfo.embeddings[selectedEmbedding];
        const recipe_id = embeddingInfo.recipe_id;
        const embeddings_id = selectedEmb.embeddings_id;
        const crop_id = selectedEmb.crop_id;

        if (newRecipeId === recipe_id) {
            toastError(strings.singleRecipeManagementPage__toastError__sameDish);
            return;
        }

        const newEmbeddingInfo = {
            ...embeddingInfo,
            embeddings: embeddingInfo.embeddings.filter((emb, i) => i !== selectedEmbedding),
            number_of_embeddings: embeddingInfo.number_of_embeddings - 1
        };

        try {
            await reassignEmbedding(queryLocation, recipe_id, embeddings_id, crop_id, newRecipeId);
            setEmbeddingInfo(newEmbeddingInfo);
            unselect();
            await fetchSingleEmbeddingInfo();
            toastSuccess(strings.singleRecipeManagementPage__toastSuccess__reassignedDish);
        } catch (error) {
            handleAxiosError(error, {}, strings.singleRecipeManagementPage__toastError__genericReassignEmbeddingApiError);
        }
    }, [embeddingInfo, queryLocation, selectedEmbedding, unselect, fetchSingleEmbeddingInfo]);

    const prevButtonRef = useRef(null);
    const nextButtonRef = useRef(null);

    const handleKeyDown = useCallback(async (e) => {
        if (e.repeat) return;
        switch (e.which) {
            case 37:
                // left arrow pressed
                prevButtonRef.current.click()
                break;
            case 39:
                // right arrow pressed
                nextButtonRef.current.click()
                break;
            default:
                break;
        }
    }, []);

    const prevHandleKeyDown = usePrevious(handleKeyDown);

    useEffect(() => {
        document.removeEventListener("keydown", prevHandleKeyDown, false);
        document.addEventListener("keydown", handleKeyDown, false);

        return () => {
            document.removeEventListener("keydown", handleKeyDown, false);
        };
    }, [prevHandleKeyDown, handleKeyDown]);

    return (
        <>
            <Header />
            <div className={css.container}>
                {
                    embeddingInfo ?
                    <>
                        {embeddingInfo && <h1>{strings.formatString(strings.singleRecipeManagementPage__pageTitle, {recipe_name: embeddingInfo.recipe_name})}</h1>}
                        <div className={css.buttonBar}>
                            <LinkWithQuery
                                    className={'btn btn-primary'}
                                    state={props.location.state?.searchText ? {initialSearchText: props.location.state.searchText} : {}}
                                    to={{pathname: '/admin/management'}}
                                    >
                                        {strings.singleRecipeManagementPage__backButtonLabel}
                            </LinkWithQuery>
                            { prevPage !== null || nextPage !== null ? <>
                                <LinkWithQuery
                                    className={'btn btn-primary' + (prevPageIdx === props.location.state.currEmbIndex ? ' disabled' : '')}
                                    disabled={prevPageIdx === props.location.state.currEmbIndex}
                                    copyState={true}
                                    innerRef={prevButtonRef}
                                    state={{currEmbIndex: prevPageIdx}}
                                    to={{pathname: '/admin/management/' + props.match.params.site + '/' + prevPage}}
                                    >
                                        <i className='ni ni-bold-left'></i>
                                </LinkWithQuery>
                                <LinkWithQuery
                                    className={'btn btn-primary' + (nextPageIdx === props.location.state.currEmbIndex ? ' disabled' : '')}
                                    disabled={nextPageIdx === props.location.state.currEmbIndex}
                                    copyState={true}
                                    innerRef={nextButtonRef}
                                    state={{currEmbIndex: nextPageIdx}}
                                    to={{pathname: '/admin/management/' + props.match.params.site + '/' + nextPage}}
                                    >
                                        <i className='ni ni-bold-right'></i>
                                </LinkWithQuery>
                            </> : ''}
                        </div>
                        {
                            embeddingInfo?.embeddings.length > 0 ?
                            <div className={css.imagesContainer}>
                                {renderImages(embeddingInfo, handleEditOrDeleteButtonClick)}
                            </div>
                            :
                            <h2>{strings.singleRecipeManagementPage__noExampleFound}</h2>
                        }
                    </>
                    :
                    <h1>{strings.singleRecipeManagementPage__loading}</h1>
                }
            </div>
            <DeleteModal
                isOpen={selectedEmbedding !== null && selectedAction === ACTIONS.DELETE}
                toggle={() => unselect()}
                onDelete={() => handleModalDelete()}
                prompt={strings.singleRecipeManagementPage__deleteModalPrompt}
            />
            <ReassignRecipeModal
                isOpen={selectedEmbedding !== null && selectedAction === ACTIONS.EDIT}
                toggle={() => unselect()}
                location={props.match.params.site}
                onReassign={(recipe) => handleModalReassign(recipe.value)}
            />
        </>
    );
}
