import './GalleryImage.css'

import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { getImage } from 'services/api';
import { usePrevious } from 'hooks/usePrevious';

export default function GalleryImage(props) {

  const [loading, setLoading] = useState(true);
  const [blobUrl, setBlobUrl] = useState('#');

  const previousSrc = usePrevious(props.src);

  useEffect(() => {
    async function fetchImage() {
      try {
        const response = await getImage(props.src);
        setBlobUrl(URL.createObjectURL(response.data));
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }

    if (props.src !== previousSrc) {
      setLoading(true);
      fetchImage();
    }

    return function cleanup() {
      URL.revokeObjectURL(blobUrl);
    }
  }, [props.src, blobUrl, previousSrc]);

  return (
    <>
      {
        loading ?
        <div className='galleryImageSkeletonLoader'></div>
        :
        <Link to={props.link}>
          <img 
            className='gallery-img'
            src={blobUrl}
            alt="">
          </img>
        </Link>
      }
    </>    
  );
}