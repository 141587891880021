/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import 'index.css'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import 'react-toastify/dist/ReactToastify.css';

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from 'react-toastify';
import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserPlus, faTrash, faUserPen, faPen, faUtensils, faUpload, faUserGear, faBookOpen} from "@fortawesome/free-solid-svg-icons";
import { faImages, faChartPie, faFileCircleExclamation, faDatabase, faCirclePlus, faCircleMinus } from "@fortawesome/free-solid-svg-icons";

library.add(faUserPlus);
library.add(faTrash);
library.add(faUserPen);
library.add(faPen);
library.add(faUtensils);
library.add(faUpload);
library.add(faUserGear);
library.add(faImages);
library.add(faChartPie);
library.add(faFileCircleExclamation);
library.add(faDatabase);
library.add(faCirclePlus, faCircleMinus);
library.add(faBookOpen);

ReactDOM.render(
  <>
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/admin" component={(props) => <AdminLayout {...props} />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Redirect from="/" to="/admin/gallery" />
      </Switch>
    </BrowserRouter>
    <ToastContainer
      position="bottom-right"
      theme="colored"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </>,
  document.getElementById("root")
);
