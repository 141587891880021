import React from 'react';
import { auth } from 'services/api';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {

  // Add your own authentication on the below line.
  const isLoggedIn = auth.isLoggedIn();

  return (
    <Route
      {...rest}
      render={props => {
        return isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/auth/login', state: { returnUrl: props.location } }} />
        )
      }}
    />
  )
}

export default PrivateRoute